import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    CompactPeoplePicker,
    DirectionalHint,
    HoverCard,
    HoverCardType,
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSelectedProps,
    IPersonaProps,
    ISuggestionItemProps,
    ITag,
    Label,
    MessageBarType,
    PeoplePickerItem,
    Persona,
    PersonaSize,
    ValidationState,
    getTheme,
} from "office-ui-fabric-react";
import * as React from "react";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import "./AssigneeComponent.less";
import { Logger, Log } from "../../../services/Logger";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import LabelComponent from "../label/LabelComponent";
import LinkComponent from "../linkComponent/LinkComponent";

export interface AssigneeComponentProps extends LocalizeContextProps {
    className?: string;
    assignees: IPersonaProps[];
    formValidationHelpers: FormValidationHelpers;
    selectedProject: ITag | null;
    currentUserEmail: string | null;
    disabled: boolean;
    onAssigneeChanged: (assignees: IPersonaProps[]) => void;
    onError: (message: string | null, type: MessageBarType) => void;
    logger: Logger;
    projectsService: IProjectsService;
    label: string;
    required: boolean;
    showAssignToMe?: boolean;
    maxAllowed?: number;
    type?: string;
    sameCcAndTo?: boolean;
    panelString?: string;
}

export interface AssigneeComponentState {
    assignees: IPersonaProps[];
    validationErrors: Number[];
    focusRefreshFlag: Boolean;
    shouldShowEmailFormatErrorMessage: Boolean;
}

class AssigneeComponent extends React.Component<AssigneeComponentProps, AssigneeComponentState> {
    constructor(props: AssigneeComponentProps, context: AssigneeComponentState) {
        super(props, context);

        this.state = {
            assignees: [],
            validationErrors: [],
            focusRefreshFlag: true,
            shouldShowEmailFormatErrorMessage: false,
        };
    }

    async componentDidUpdate(
        prevProps: Readonly<AssigneeComponentProps>,
        prevState: Readonly<AssigneeComponentState>,
        snapshot?: any
    ): Promise<void> {
        if (prevProps.assignees !== this.props.assignees) {
            this.setState({ assignees: this.props.assignees, focusRefreshFlag: !this.state.focusRefreshFlag });
            return;
        }

        if (prevState.focusRefreshFlag !== this.state.focusRefreshFlag) {
            this.emailChecks();
        }
        if (prevProps.sameCcAndTo !== this.props.sameCcAndTo) {
            this.emailChecks();
        }
    }

    async onAssignedToFiltered(filter: string, selectedItems?: IPersonaProps[]): Promise<IPersonaProps[]> {
        const projectNrn = this.props.selectedProject?.key;
        if (!filter || !projectNrn) {
            return [];
        }
        let personaProps: IPersonaProps[];
        const currentAssigneeNrns: (string | undefined)[] = this.props.assignees.map(
            (assignee) => (assignee as any).data?.nrn
        );
        try {
            const teamMembers = await this.props.projectsService.getAllTeamMembersNormalized(projectNrn, filter);
            personaProps = this.props.formValidationHelpers.getPersonaPropsFromTeamMembers(teamMembers);
            personaProps = personaProps.filter((personaProp) => {
                if (!(personaProp as any).data?.nrn) {
                    return false;
                }
                return !currentAssigneeNrns.includes((personaProp as any).data?.nrn);
            });
        } catch {
            personaProps = [];
        }

        return personaProps;
    }

    private onAssignedToChanged(items?: IPersonaProps[]) {
        if (!items || !items.length) {
            this.updateAssignees([]);
            return;
        }
        let recentlyAdded = items.pop() as IPersonaProps & { data?: { email?: string } };
        let uniqueItems = items.filter((item) => {
            if (item.text === recentlyAdded.data?.email) {
                return false;
            }
            if (item.text === recentlyAdded.text) {
                return false;
            }
            if ((item as any).data?.email === recentlyAdded.text) {
                recentlyAdded = item;
                return false;
            }
            return true;
        });
        uniqueItems.push(recentlyAdded);
        if (this.props.maxAllowed) {
            uniqueItems = uniqueItems.slice(-this.props.maxAllowed);
        }
        this.updateAssignees(uniqueItems);
        this.emailChecks();
    }

    onCreateGenericItem(input: string, validationState: ValidationState): any {
        return {
            text: input,
            ValidationState: validationState,
        };
    }

    validateEmailInput(input: string): ValidationState {
        this.emailChecks();
        return this.props.formValidationHelpers.validateEmailInput(input);
    }

    validateToAndCcEmails(assignees?: IPersonaProps[] | null): Number[] {
        let validatedType;
        const emailErrors = [] as any;
        const currentAssignees = assignees ? assignees : this.state.assignees;
        currentAssignees.map((assignee) => {
            if (assignee.secondaryText) {
                validatedType = this.props.formValidationHelpers.validateEmailInput(assignee.secondaryText);
                emailErrors.push(validatedType);
            } else if (assignee.text) {
                validatedType = this.props.formValidationHelpers.validateEmailInput(assignee.text);
                emailErrors.push(validatedType);
            }
        });
        return emailErrors;
    }

    addStylingForValidation(errors?: Number[]): any {
        let toSection;
        let ccSection;
        let toActionItemSection;
        switch (this.props.type) {
            case "to":
                toSection = document.querySelector(".newforma-toPicker .ms-BasePicker-text");
                break;
            case "cc":
                ccSection = document.querySelector(".newforma-ccPicker .ms-BasePicker-text");
                break;
            case "to-actionItem":
                toActionItemSection = document.querySelector(".actionItemAssignee .ms-BasePicker-text");
                break;
        }

        if (this.props.type && errors) {
            const isError = errors.indexOf(1) !== -1;
            if (this.props.type === "to" && toSection) {
                if (this.props.panelString === "fileTransfer") {
                    this.toggleErrorStyles(toSection, "invalidErrorFileTransfer", isError);
                }
                // if validation errors array does not contain "warning" elements, then remove "invalidError" class for stylying, otherwise add "invalidError"
                this.toggleErrorStyles(toSection, "invalidError", isError);
                return;
            }
            // needed for action item component
            if (this.props.type === "to-actionItem" && toActionItemSection) {
                // if validation errors array does not contain "warning" elements, then remove "invalidError" class for stylying, otherwise add "invalidError"
                this.toggleErrorStyles(toActionItemSection, "invalidError", isError);
                return;
            }
            if (this.props.type === "cc" && ccSection) {
                if (this.props.panelString === "fileTransfer") {
                    this.toggleErrorStyles(ccSection, "invalidErrorFileTransfer", isError);
                    this.props.sameCcAndTo
                        ? ccSection.classList.add("invalidErrorFileTransferToCC")
                        : ccSection.classList.remove("invalidErrorFileTransferToCC");
                }
                // if validation errors array does not contain "warning" elements, then remove "invalidError" class for stylying, otherwise add "invalidError"
                this.toggleErrorStyles(ccSection, "invalidErrorCC", isError);
                // add error styling for same to and cc if emails are the same, otherwise remove class for styling
                this.props.sameCcAndTo
                    ? ccSection.classList.add("invalidError")
                    : ccSection.classList.remove("invalidError");
            }
        }
    }

    toggleErrorStyles(element: Element | null, className: string, isError: boolean) {
        if (isError) {
            this.addErrorStyles(element, className);
        } else {
            this.removeErrorStyles(element, className);
        }
    }

    addErrorStyles(element: Element | null, className: string) {
        element?.classList.add(className);
        this.setState({ shouldShowEmailFormatErrorMessage: true });
    }

    removeErrorStyles(element: Element | null, className: string) {
        element?.classList.remove(className);
        this.setState({ shouldShowEmailFormatErrorMessage: false });
    }

    emailChecks(assignees?: IPersonaProps[]) {
        const emailErrors = this.validateToAndCcEmails(assignees ? assignees : null);
        this.setState({ validationErrors: emailErrors });
        this.addStylingForValidation(emailErrors);
    }

    renderSuggestionsItem(props: IPersonaProps, itemProps: ISuggestionItemProps<any>): JSX.Element {
        const discipline = (props as any).data?.discipline ? `(${(props as any).data?.discipline})` : "";
        const text = `${props.text} ${discipline}`.trim();
        const updatedProps: IPersonaProps = {
            ...props,
            text,
            size: PersonaSize.size24,
            className: "newforma-assigneeSuggestionsItem",
        };
        return <Persona {...updatedProps} />;
    }

    renderItem(props: any): JSX.Element {
        const updatedProps: IPeoplePickerItemSelectedProps = {
            ...props,
            item: {
                ...props.item,
                showSecondaryText: false,
                onRenderPrimaryText: () => props.item.text, // this is needed to prevent the default behavior of showing a tooltip for overflow
            },
        };
        return (
            <HoverCard
                plainCardProps={{
                    onRenderPlainCard: this.renderAssigneeCard.bind(this),
                    renderData: props.item,
                    directionalHint: DirectionalHint.topLeftEdge,
                    gapSpace: 5,
                }}
                instantOpenOnClick
                type={HoverCardType.plain}
                key={`hovercard-${props.item.text}`}
            >
                <PeoplePickerItem {...updatedProps} />
            </HoverCard>
        );
    }

    renderAssigneeCard(personaProps: IPersonaProps): JSX.Element {
        const data = (personaProps as any).data;
        const text = data?.email || personaProps.text;
        const secondaryText = data?.discipline;
        const updatedProps: IPersonaProps = {
            ...personaProps,
            text,
            secondaryText,
            showSecondaryText: !!secondaryText,
            size: PersonaSize.size24,
            className: "newforma-assigneeSuggestionsItem",
            coinProps: {
                className: "newforma-hoverCoin",
            },
        };
        return <Persona {...updatedProps} />;
    }

    private async assignToMe(): Promise<void> {
        Log.info("assignToMe");
        let currentAssignees = this.props.assignees;
        const projectNrn = this.props.selectedProject?.key;
        const email = this.props.currentUserEmail;
        if (this.props.disabled) {
            return;
        }
        if (!projectNrn || !email) {
            this.props.onError(
                this.props.translate("ACTION_ITEM.ASSIGN_TO_ME_ERROR") as string,
                MessageBarType.severeWarning
            );
            return;
        }
        try {
            const teamMembers = await this.props.projectsService.getTeamMembersNormalized(projectNrn, email);
            let assignees;
            currentAssignees = currentAssignees.filter((assignee) => {
                return !(assignee.text === email);
            });
            if (teamMembers.length === 1) {
                const myPersonaProp = this.props.formValidationHelpers.getPersonaPropsFromTeamMembers(
                    teamMembers
                )[0] as any;
                // remove existing record from list
                currentAssignees = currentAssignees.filter((assignee) => {
                    return !(myPersonaProp.data.nrn === (assignee as any).data?.nrn);
                });
                assignees = [...currentAssignees, myPersonaProp];
            } else {
                assignees = [...currentAssignees, { text: email }];
            }
            this.updateAssignees(assignees);
        } catch (error) {
            this.props.logger.error("AssigneeComponent error", error);
            this.updateAssignees([...currentAssignees, { text: email }]);
        }
    }

    private updateAssignees(assignees: IPersonaProps[]): void {
        this.setState({ assignees });
        this.props.onAssigneeChanged(assignees);
    }

    private getPickerSuggestionProps(): IBasePickerSuggestionsProps {
        return {
            suggestionsHeaderText: this.props.translate("ACTION_ITEM.TEAM_MEMBER_HEADER") as string,
            noResultsFoundText: this.props.selectedProject
                ? (this.props.translate("ACTION_ITEM.NO_RESULTS_FOUND") as string)
                : (this.props.translate("ACTION_ITEM.NO_PROJECT_SELECTED") as string),
            loadingText: this.props.translate("ACTION_ITEM.LOADING") as string,
            showRemoveButtons: false,
        };
    }

    private renderAssignToMeButton(): JSX.Element {
        return (
            <>
                {this.props.disabled ? (
                    <Label className="newforma-assignToMeDisabled">
                        {this.props.translate("ACTION_ITEM.ASSIGN_TO_ME")}
                    </Label>
                ) : (
                    <LinkComponent
                        text={this.props.translate("ACTION_ITEM.ASSIGN_TO_ME") as string}
                        onClick={this.assignToMe.bind(this)}
                        className="newforma-assignToMe"
                    />
                )}
            </>
        );
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div className={`${this.props.className} newforma-assigneeComponent`}>
                <LabelComponent text={this.props.label} required={this.props.required} />
                <CompactPeoplePicker
                    className="newforma-assignToPicker"
                    pickerSuggestionsProps={this.getPickerSuggestionProps()}
                    selectedItems={this.state.assignees}
                    onResolveSuggestions={this.onAssignedToFiltered.bind(this)}
                    onChange={this.onAssignedToChanged.bind(this)}
                    onValidateInput={this.validateEmailInput.bind(this)}
                    resolveDelay={300}
                    createGenericItem={this.onCreateGenericItem.bind(this)}
                    disabled={this.props.disabled}
                    onRenderSuggestionsItem={this.renderSuggestionsItem.bind(this)}
                    onRenderItem={this.renderItem.bind(this)}
                    onBlur={() => this.setState({ focusRefreshFlag: !this.state.focusRefreshFlag })}
                    inputProps={{
                        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => this.emailChecks(),
                    }}
                    styles={{
                        root: {
                            selectors: {
                                ":focus-within": {
                                    borderRadius: "2px",
                                    margin: "-2px",
                                },
                            },
                        },
                    }}
                />
                {this.state.shouldShowEmailFormatErrorMessage ? (
                    <p className="emailValidationMessage">{this.props.translate("SHARED.ERRORS.EMAIL_VALIDATION")}</p>
                ) : null}
                {this.props.sameCcAndTo && !this.state.shouldShowEmailFormatErrorMessage ? (
                    <p className="emailValidationMessage">
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_1")}
                        <span className="emailValidationBoldMessage">
                            {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_2")}
                        </span>
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_3")}
                        <span className="emailValidationBoldMessage">
                            {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_4")}
                        </span>
                        {this.props.translate("SHARED.ERRORS.TO_AND_CC_VALIDATION_5")}
                    </p>
                ) : null}
                {this.props.showAssignToMe ? this.renderAssignToMeButton() : null}
            </div>
        );
    }
}

export default withLocalize(AssigneeComponent);
