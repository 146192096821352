import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import * as React from "react";
import { DatePicker, DirectionalHint, Label, getTheme } from "office-ui-fabric-react";
import "./TranslatedDatePickerComponent.less";
import LinkComponent from "../linkComponent/LinkComponent";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import { DateHelper } from "../../../helpers/DateHelpers";

export interface TranslatedDatePickerComponentProps extends LocalizeContextProps {
    date?: Date | undefined;
    onDateChange: (date: Date | undefined) => void;
    disabled: boolean;
    label: string;
    required: boolean;
    className?: string;
    minDate?: Date;
    maxDate?: Date;
    clearDateButtonVisibility: ElementVisibility;
    isReminderComponent?: boolean;
    isFiling?: boolean;
    shouldValidateDateInPast?: boolean;
    onDateCheck?: (value: boolean) => void;
}

export interface TranslatedDatePickerComponentState {
    selectedDate: Date | undefined;
    selectedDateString: String | undefined;
    dateError?: Boolean;
}

class TranslatedDatePickerComponent extends React.Component<
    TranslatedDatePickerComponentProps,
    TranslatedDatePickerComponentState
> {
    constructor(props: TranslatedDatePickerComponentProps, context: TranslatedDatePickerComponentState) {
        super(props, context);

        this.state = {
            selectedDate: props.date,
            selectedDateString: "",
            dateError: false,
        };
    }

    async componentDidMount(): Promise<void> {
        if (this.state.selectedDateString === "") {
            this.updateSelectedDateString(this.props.date);
        }
    }

    async componentDidUpdate(
        prevProps: Readonly<TranslatedDatePickerComponentProps>,
        prevState: Readonly<TranslatedDatePickerComponentState>
    ): Promise<void> {
        if (prevProps.date !== this.props.date || (this.state.selectedDate && !this.state.selectedDateString)) {
            this.updateSelectedDateString(this.props.date);
            this.setState({ selectedDate: this.props.date });
        }
    }

    private getMonths(): string[] {
        return [
            this.props.translate("DATES.MONTHS.JANUARY") as string,
            this.props.translate("DATES.MONTHS.FEBRUARY") as string,
            this.props.translate("DATES.MONTHS.MARCH") as string,
            this.props.translate("DATES.MONTHS.APRIL") as string,
            this.props.translate("DATES.MONTHS.MAY") as string,
            this.props.translate("DATES.MONTHS.JUNE") as string,
            this.props.translate("DATES.MONTHS.JULY") as string,
            this.props.translate("DATES.MONTHS.AUGUST") as string,
            this.props.translate("DATES.MONTHS.SEPTEMBER") as string,
            this.props.translate("DATES.MONTHS.OCTOBER") as string,
            this.props.translate("DATES.MONTHS.NOVEMBER") as string,
            this.props.translate("DATES.MONTHS.DECEMBER") as string,
        ];
    }

    private getShortMonths(): string[] {
        return [
            this.props.translate("DATES.MONTHS_SHORT.JANUARY") as string,
            this.props.translate("DATES.MONTHS_SHORT.FEBRUARY") as string,
            this.props.translate("DATES.MONTHS_SHORT.MARCH") as string,
            this.props.translate("DATES.MONTHS_SHORT.APRIL") as string,
            this.props.translate("DATES.MONTHS_SHORT.MAY") as string,
            this.props.translate("DATES.MONTHS_SHORT.JUNE") as string,
            this.props.translate("DATES.MONTHS_SHORT.JULY") as string,
            this.props.translate("DATES.MONTHS_SHORT.AUGUST") as string,
            this.props.translate("DATES.MONTHS_SHORT.SEPTEMBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.OCTOBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.NOVEMBER") as string,
            this.props.translate("DATES.MONTHS_SHORT.DECEMBER") as string,
        ];
    }

    private getDays(): string[] {
        return [
            this.props.translate("DATES.DAYS.SUNDAY") as string,
            this.props.translate("DATES.DAYS.MONDAY") as string,
            this.props.translate("DATES.DAYS.TUESDAY") as string,
            this.props.translate("DATES.DAYS.WEDNESDAY") as string,
            this.props.translate("DATES.DAYS.THURSDAY") as string,
            this.props.translate("DATES.DAYS.FRIDAY") as string,
            this.props.translate("DATES.DAYS.SATURDAY") as string,
        ];
    }

    private getShortDays(): string[] {
        return [
            this.props.translate("DATES.DAYS_SHORT.SUNDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.MONDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.TUESDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.WEDNESDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.THURSDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.FRIDAY") as string,
            this.props.translate("DATES.DAYS_SHORT.SATURDAY") as string,
        ];
    }

    private onDateChange(date: Date | null | undefined): void {
        if (!date) {
            return;
        }
        this.props.onDateChange(date);
    }

    private clearDate(): void {
        // there's an open PR for a fix for this from microsoft https://github.com/DiscipleTools/disciple-tools-theme/issues/643
        // workaround until fix is officially implemented
        this.props.onDateChange(undefined);
        this.setState({ selectedDate: undefined });
    }

    private updateSelectedDateString(date: Date | null | undefined): void {
        const formattedDateString = date?.toLocaleDateString(undefined, {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
        this.setState({ selectedDateString: formattedDateString });
        this.validateIfDateIsInPast(date);
    }

    private validateIfDateIsInPast(date: Date | null | undefined): void {
        if (this.props.shouldValidateDateInPast) {
            const isDateInThePast = DateHelper.isDateInThePast(date);
            // if selected date is in the past, show error message and add className for styling
            this.setState({ dateError: isDateInThePast });
            if (this.props.onDateCheck) {
                this.props.onDateCheck(isDateInThePast);
            }
            const reminderElement = document.querySelector(".newforma-datePicker .ms-TextField-fieldGroup");
            isDateInThePast
                ? reminderElement?.classList.add("invalidError")
                : reminderElement?.classList.remove("invalidError");
        }
    }

    private clearDateStyle(): string {
        switch (this.props.clearDateButtonVisibility) {
            case ElementVisibility.Visible:
                return "";
            case ElementVisibility.Hidden:
                return "newforma-hidden";
            case ElementVisibility.None:
                return "newforma-none";
        }
    }

    render(): JSX.Element {
        const theme = getTheme();
        return (
            <div
                className={`newforma-translatedDatePickerComponent ${this.props.className} ${
                    this.props.shouldValidateDateInPast ? "validationStyling" : ""
                }`}
            >
                <Label className="newforma-datePicker" required={this.props.required}>
                    {this.props.label}
                </Label>
                <DatePicker
                    className="newforma-datePicker"
                    isMonthPickerVisible={false}
                    onSelectDate={this.onDateChange.bind(this)}
                    value={this.state.selectedDate}
                    disabled={this.props.disabled}
                    allowTextInput={false}
                    disableAutoFocus={false}
                    strings={{
                        days: this.getDays(),
                        shortDays: this.getShortDays(),
                        goToToday: this.props.translate("DATES.GO_TO_TODAY") as string,
                        months: this.getMonths(),
                        shortMonths: this.getShortMonths(),
                    }}
                    formatDate={() => `${this.state.selectedDateString}`}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    styles={{
                        callout: {
                            selectors: {
                                "& .ms-DatePicker-day--highlighted": {
                                    backgroundColor: `${theme.palette.neutralLighter} !important`,
                                },
                                "& .ms-DatePicker-day--today > span": {
                                    color: `${theme.palette.black} !important`,
                                },
                                "& .ms-Callout-main": {
                                    top: this.props.isReminderComponent ? "-250px" : "auto",
                                },
                            },
                        },
                    }}
                    calloutProps={{
                        directionalHint: this.props.isReminderComponent
                            ? DirectionalHint.topLeftEdge
                            : DirectionalHint.bottomLeftEdge,
                    }}
                />
                {this.state.dateError && this.props.shouldValidateDateInPast ? (
                    <p className="emailValidationMessage">{this.props.translate("SHARED.ERRORS.DATE_VALIDATION")}</p>
                ) : null}
                <LinkComponent
                    text={this.props.translate("ACTION_ITEM.CLEAR_DATE") as string}
                    onClick={this.clearDate.bind(this)}
                    className={
                        this.props.isFiling
                            ? `newforma-dateClearFiling ${this.clearDateStyle()}`
                            : `newforma-dateClear ${this.clearDateStyle()}`
                    }
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default withLocalize(TranslatedDatePickerComponent);
