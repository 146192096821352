import "./SubmittalReviewResponseComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { AppPage } from "../../shared/navigationHeader/NavigationHeaderComponent";
import {
    DefaultButton,
    IPersonaProps,
    ISuggestionItemProps,
    ITag,
    Label,
    MessageBarType,
    ProgressIndicator,
    TagPicker,
    TextField,
} from "office-ui-fabric-react";
import { Logger } from "../../../services/Logger";
import SuggestedProjectPickerComponent from "../../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import AttachmentsComponent from "../../shared/attachments/AttachmentsComponent";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import SuggestedItemWithSubtextComponent from "../../shared/pickerSuggestedItems/suggestedItemWithSubtext/SuggestedItemWithSubtextComponent";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { ApiRequestErrorLevel } from "../../../models/ApiRequestErrorWithMessage";
import { SubmittalsApiService } from "../../../services/NewformaApi/SubmittalsApiService";
import { SubmittalReviewResponseBodyParams } from "../../../models/workflow/submittal/SubmittalWorkflowActions";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { WorkflowActionType } from "../../../models/workflow/WorkflowActionType";
import LabelComponent from "../../shared/label/LabelComponent";
import { AnalyticsManager } from "../../../services/AnalyticsManager";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { NrnServiceWrapper } from "../../../services/NrnServiceWrapper";
import ChipPickerComponent from "../../shared/chipPicker/ChipPickerComponent";
import { DetailedKeyword, KeywordListType } from "../../../models/ProjectKeywordsResponse";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import { MsGraphApiService } from "../../../services/MsGraphApiService";
import KeywordsDropdown from "../../shared/keywordsDropdown/KeywordsDropdown";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ProjectsCacheKeys } from "../../../models/StorageKeys";
import { Submittal } from "../../../models/workflow/submittal/SubmittalsResponse";
import { InvalidateCacheService } from "../../../services/NewformaApi/InvalidateCacheService";
import { AttachmentItem } from "../../../models/shared/AttachmentList";
import HTMLEditor from "../../shared/editor/Editor";
import { ConfigurationService } from "../../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../../helpers/AttachmentDataHelpers";

export interface SubmittalReviewResponseComponentProps extends LocalizeContextProps {
    logger: Logger;
    onExpiredSession: () => void;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    submittalsApiService: SubmittalsApiService;
    projectsService: IProjectsService;
    mailboxItem: MailboxItem | null;
    analyticsManager: AnalyticsManager;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
}

export interface SubmittalReviewResponseComponentState {
    projects: ITag[];
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    isFiling: boolean;
    attachments: AttachmentItem[];
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    remarks: string;
    responses: DetailedKeyword[];
    selectedResponse: DetailedKeyword | null;
    isLoadingKeywords: boolean;
    isLoadingSubmittals: boolean;
    submittals: ITag[];
    selectedSubmittal: ITag | undefined;
    fileUploadIsInProgress: boolean;
    failedUploadAttachmentNames: string[];
    receivedDate: Date | undefined;
    keywords: IPersonaProps[];
    selectedKeywords: IPersonaProps[];
    allowCustomKeywords: boolean;
    subject: string;
    areToAndCCSame: boolean;
}

export const submittalReviewResponseComponentDefaultState: SubmittalReviewResponseComponentState = {
    projects: [],
    selectedProject: null,
    isLoadingProjects: true,
    isFiling: false,
    attachments: [],
    from: [],
    to: [],
    cc: [],
    remarks: "",
    responses: [],
    selectedResponse: null,
    isLoadingKeywords: false,
    isLoadingSubmittals: false,
    submittals: [],
    selectedSubmittal: undefined,
    fileUploadIsInProgress: false,
    failedUploadAttachmentNames: [],
    receivedDate: undefined,
    keywords: [],
    selectedKeywords: [],
    allowCustomKeywords: false,
    subject: "",
    areToAndCCSame: false,
};

class SubmittalReviewResponseComponent extends React.Component<
    SubmittalReviewResponseComponentProps,
    SubmittalReviewResponseComponentState
> {
    constructor(props: SubmittalReviewResponseComponentProps, context: SubmittalReviewResponseComponentState) {
        super(props, context);

        this.state = submittalReviewResponseComponentDefaultState;
    }

    async componentDidMount() {
        this.props.logger.info("File Email as Submittal Review Response component mounted");
        this.props.onSetNavigationPage(AppPage.FileAsSubmittalReviewResponse);
        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    async componentDidUpdate(prevProps: Readonly<SubmittalReviewResponseComponentProps>) {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({
                ...submittalReviewResponseComponentDefaultState,
                projects: state.projects,
                selectedProject: state.selectedProject,
                from: state.from,
                to: state.to,
                cc: state.cc,
            }));

            await this.populateDefaults();
            this.setState({
                isLoadingProjects: false,
            });
        }
    }

    private async populateDefaults(): Promise<void> {
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );
        const remarks = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();
        const subject =
            (await this.props.officeWrapper.getCurrentEmailSubject()) ||
            (this.props.translate("SUBMITTALS.RESPONSE.NO_SUBJECT") as string);
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(remarks, attachmentDetails);

        this.setState({
            attachments: attachmentsNoCloud,
            remarks: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : remarks.trim(),
            receivedDate,
            subject: subject.trim(),
        });
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingSubmittals();
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            this.props.logger.error("SubmittalReviewResponse loading projects", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.clearKeywords();

        this.setState({ selectedProject });

        if (selectedProject) {
            await Promise.all([this.loadKeywords(selectedProject), this.loadSubmittals(selectedProject)]);
        }
    }

    private clearKeywords(): void {
        this.setState({
            selectedResponse: null,
            responses: [],
            selectedSubmittal: undefined,
            submittals: [],
            keywords: [],
            selectedKeywords: [],
            allowCustomKeywords: false,
        });
    }

    private async loadSubmittals(project: ITag): Promise<void> {
        this.setState({ isLoadingSubmittals: true });
        try {
            const submittalsResponse = await this.props.submittalsApiService.getForwardedSubmittals(project.key);
            const submittals = submittalsResponse.map((submittal: Submittal) => ({
                name: submittal.number,
                key: submittal.nrn,
                description: submittal.subject,
            }));
            this.setState({ submittals });
        } catch (error) {
            this.props.logger.error("SubmittalReviewResponse loading submittals", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            const errorMessage =
                (error as any).status === 405
                    ? this.props.translate("SUBMITTALS.ACTIVITY_CENTER_DISABLED_ERROR")
                    : this.props.translate("SUBMITTALS.RESPONSE.FAILED_LOADING_SUBMITTALS");
            this.props.onShowToast(errorMessage as string, MessageBarType.severeWarning);
        } finally {
            this.setState({ isLoadingSubmittals: false });
        }
    }

    private async loadKeywords(project: ITag): Promise<void> {
        this.setState({ isLoadingKeywords: true });
        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(project.key);
        const actionType = isCloudProject ? WorkflowActionType.ReviewResponse : WorkflowActionType.ReviewResponseNpc;
        try {
            const workflowActionKeywordsResponse = await this.props.submittalsApiService.getWorkflowActionKeywords(
                project.key,
                actionType
            );
            const sortedWorkflowActionKeywords = ProjectHelper.sortKeywords(workflowActionKeywordsResponse);
            this.setState({ responses: sortedWorkflowActionKeywords });

            if (!isCloudProject) {
                const keywordsResponse = await this.props.projectsService.getProjectKeywords(
                    project.key,
                    KeywordListType.SubmittalKeyword
                );
                const sortedKeywords = ProjectHelper.sortKeywords(keywordsResponse);
                const keywords: IPersonaProps[] = sortedKeywords.map((keyword) => ({
                    text: keyword.name,
                    data: keyword,
                }));
                this.setState({ keywords, allowCustomKeywords: keywordsResponse.allowCustom });
            }
        } catch (error) {
            this.props.logger.error("SubmittalReviewResponse loading keywords", error);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.FAILED_LOADING_KEYWORDS") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onToChange(people: IPersonaProps[]): void {
        this.setState({ to: people });
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState({ cc: people });
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".submittalReviewResponseComponent")?.scrollTo({
            top: document.querySelector(".submittalReviewResponseComponent")?.scrollHeight,
            behavior: "auto",
        });
    }

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
    }

    private onResponseSelectionChange(option: DetailedKeyword | null): void {
        this.setState({ selectedResponse: option });
    }

    private getKeywordPlaceholder(items: any[], selectText: string): string {
        if (!this.state.selectedProject) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_PROJECT_SELECTED") as string;
        }

        if (this.state.isLoadingKeywords) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.LOADING") as string;
        }

        if (!items.length) {
            return this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_ITEMS") as string;
        }

        return selectText;
    }

    private onRenderSubmittalNumbers(props: ITag, itemProps: ISuggestionItemProps<any>): JSX.Element {
        return <SuggestedItemWithSubtextComponent primaryText={props.name} subtext={(props as any).description} />;
    }

    async onSubmittalsFiltered(filter: string, selectedItems?: ITag[]): Promise<ITag[]> {
        if (!filter) {
            return this.state.submittals;
        }

        return this.state.submittals.filter((x) => {
            return (
                x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                (x as any).description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        });
    }

    private onSubmittalSelected(selectedItem?: ITag): ITag | null {
        if (!selectedItem) {
            this.setState({ selectedSubmittal: undefined, selectedKeywords: [] });
            return null;
        }

        this.props.submittalsApiService
            .getSubmittalDetails(selectedItem.key as string, this.state.selectedProject?.key as string)
            .then((submittalDetails) => {
                const keywords = submittalDetails.keywords.map((keyword) => ({ text: keyword.name, data: keyword }));
                this.setState({ selectedKeywords: keywords });
            })
            .catch((error) => {
                this.handleApiError(error);
            });
        this.setState({
            selectedSubmittal: {
                ...selectedItem,
                name: `${selectedItem.name} ${(selectedItem as any).description}`.trim(),
            },
        });

        return selectedItem;
    }

    private onSubmittalChange(items?: ITag[]): void {
        if (!items?.length) {
            this.setState({ selectedSubmittal: undefined, selectedKeywords: [] });
        }
    }

    onEmptySubmittalInputFocus(selectedItems?: ITag[]): ITag[] {
        return this.state.submittals;
    }

    private async onFormSubmit(): Promise<void> {
        this.props.logger.info("File Email as Submittal Response form submitted");
        this.setState({ isFiling: true });
        try {
            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const submittalDetails = await this.props.submittalsApiService.getSubmittalDetails(
                this.state.selectedSubmittal?.key as string,
                this.state.selectedProject?.key as string
            );
            const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(
                this.state.selectedProject?.key as string
            );

            const requestParams: SubmittalReviewResponseBodyParams = {
                from: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.from)[0],
                to: this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.to),
                response: this.props.formValidationHelpers.mapDetailedKeywordToKeyword(
                    this.state.selectedResponse as DetailedKeyword
                ),
                version: submittalDetails.version,
                cc: this.state.cc.length
                    ? this.props.formValidationHelpers.mapPersonaPropsToContact(this.state.cc)
                    : undefined,
                remarks: this.state.remarks?.trim() || undefined,
                via: this.props.submittalsApiService.getVia(this.state.selectedProject?.key as string),
                subject: isCloudProject ? undefined : this.state.subject,
                keywords: isCloudProject
                    ? undefined
                    : this.props.formValidationHelpers.mapPersonaPropsToKeywords(this.state.selectedKeywords),
                receivedDate: isCloudProject ? undefined : this.state.receivedDate?.toISOString(),
            };
            await this.props.submittalsApiService.logEmailAsSubmittalReviewerResponse(
                this.state.selectedProject?.key as string,
                messageNrn,
                this.state.selectedSubmittal?.key as string,
                requestParams,
                this.state.attachments,
                this.fileUploadCallback.bind(this),
                this.props.isFileTransferAndEditorSupported
            );

            try {
                const projectAsAny = this.state.selectedProject as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    this.state.selectedProject ?? { key: "", name: "" },
                    this.props.mailboxItem?.conversationId as string,
                    this.props.mailboxItem?.sender.emailAddress as string
                );
            } catch (error) {
                this.props.logger.error("SubmittalReviewResponse failed to update smart filing history", error);
            }

            this.props.onShowToast(
                this.props.translate("SUBMITTALS.RESPONSE.SUCCESS_MESSAGE") as string,
                MessageBarType.success
            );
            this.setState({
                selectedSubmittal: undefined,
                selectedResponse: null,
                selectedKeywords: [],
            });
        } catch (error) {
            this.handleApiError(error);
        } finally {
            this.setState({
                isFiling: false,
                fileUploadIsInProgress: false,
            });
        }
    }

    isFormValid(): boolean {
        const {
            selectedSubmittal,
            selectedResponse,
            from,
            to,
            cc,
            isFiling,
            isLoadingProjects,
            isLoadingKeywords,
            receivedDate,
            subject,
            selectedProject,
        } = this.state;
        return (
            !!selectedProject &&
            !!selectedSubmittal &&
            selectedResponse !== null &&
            !!from.length &&
            !!to.length &&
            !isFiling &&
            !isLoadingProjects &&
            !isLoadingKeywords &&
            this.props.formValidationHelpers.areAssigneesValid(from, true) &&
            this.props.formValidationHelpers.areAssigneesValid(to, true) &&
            this.props.formValidationHelpers.areAssigneesValid(cc, false) &&
            (this.isCloudProject() ? true : !!receivedDate) && // received date required for npc projects
            (this.isCloudProject() ? true : !!subject) && // subject required for npc projects
            !this.state.areToAndCCSame
        );
    }

    private handleApiError(error: any): void {
        this.props.logger.error("SubmittalReviewResponse API error", error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.WARNING) {
            this.props.onShowToast(this.props.translate(error.messageToDisplay) as string, MessageBarType.warning);
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            if (failedAttachmentNames.length) {
                const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                    .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                    .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
                this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
                return;
            }
            this.props.onShowToast(
                this.props.translate(error.messageToDisplay) as string,
                MessageBarType.severeWarning
            );
            return;
        }
        if (error.status === 501) {
            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.NL_OUTDATED") as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level === undefined) {
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.RESPONSE.FAILED_GENERIC") as string,
                MessageBarType.severeWarning
            );
        }
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]) {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    private isCloudProject(): boolean {
        const isCloud = this.props.nrnServiceWrapper.isCloudProject(this.state.selectedProject?.key as string);
        this.toggleProjectTeamVisibility(isCloud);
        return isCloud;
    }

    private toggleProjectTeamVisibility(cloudProject: Boolean) {
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        cloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private onReceivedDateChange(date: Date | undefined): void {
        this.setState({ receivedDate: date });
    }

    private onKeywordsChanged(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ subject: newValue || "" });
    }

    private async refreshProjects(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.submittalsCacheName);
        return this.loadProjects();
    }

    private onCCAndToComparison(areToAndCCSame: boolean): void {
        this.setState({ areToAndCCSame });
    }

    render(): JSX.Element {
        return (
            <div className="submittalReviewResponseComponent">
                <div className="newforma-form newforma-submittalReviewResponseForm">
                    <SuggestedProjectPickerComponent
                        logger={this.props.logger}
                        className="newforma-formSpacing"
                        projects={this.state.projects}
                        myProjects={this.state.projects}
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        disabled={this.state.isLoadingProjects || this.state.isFiling || this.state.isLoadingKeywords}
                        smartFilingManager={this.props.smartFilingManager}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailboxItem}
                        onRefresh={this.refreshProjects.bind(this)}
                        theme={this.props.theme}
                    />
                    {!this.isCloudProject() ? (
                        <div>
                            <Label required={true}>{this.props.translate("SUBMITTALS.SUBJECT_LABEL") as string}</Label>
                            <TextField
                                className="newforma-formSpacing"
                                id="submittalReviewResponse-subject"
                                value={this.state.subject}
                                onChange={this.onSubjectChange.bind(this)}
                                disabled={this.state.isFiling}
                                maxLength={256}
                            />
                        </div>
                    ) : null}
                    <LabelComponent
                        text={this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_NUMBER") as string}
                        required={true}
                    />
                    <TagPicker
                        className={`newforma-submittalNumber newforma-formSpacing ${
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            !this.state.submittals?.length
                                ? `disabledStyle`
                                : ``
                        }`}
                        selectedItems={this.state.selectedSubmittal ? [this.state.selectedSubmittal] : []}
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: this.props.translate(
                                "SUBMITTALS.RESPONSE.SUBMITTAL_NUMBER_PICKER_HEADER"
                            ) as string,
                            noResultsFoundText: this.props.translate(
                                "SUBMITTALS.RESPONSE.SUBMITTAL_NUMBER_NONE"
                            ) as string,
                        }}
                        onRenderSuggestionsItem={this.onRenderSubmittalNumbers.bind(this)}
                        disabled={
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            this.state.isFiling ||
                            !this.state.submittals?.length
                        }
                        itemLimit={1}
                        onResolveSuggestions={this.onSubmittalsFiltered.bind(this)}
                        onItemSelected={this.onSubmittalSelected.bind(this)}
                        onChange={this.onSubmittalChange.bind(this)}
                        pickerCalloutProps={{ calloutWidth: 280 }}
                        resolveDelay={300}
                        inputProps={{
                            placeholder: this.getKeywordPlaceholder(
                                this.state.submittals,
                                this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_NUMBER_PLACEHOLDER") as string
                            ),
                        }}
                        onEmptyInputFocus={this.onEmptySubmittalInputFocus.bind(this)}
                    />
                    <KeywordsDropdown
                        className="newforma-responseDropdown newforma-formSpacing"
                        id="submittalReviewResponse-responseDropdown"
                        options={this.state.responses}
                        label={this.props.translate("SUBMITTALS.RESPONSE.RESPONSE_LABEL") as string}
                        placeholder={this.props.translate("SUBMITTALS.RESPONSE.RESPONSE_PLACEHOLDER") as string}
                        disabled={
                            !this.state.selectedProject ||
                            this.state.isLoadingKeywords ||
                            this.state.isFiling ||
                            !this.state.responses.length
                        }
                        isLoading={this.state.isLoadingKeywords}
                        isProjectSelected={!!this.state.selectedProject}
                        required={true}
                        onSelectionChange={this.onResponseSelectionChange.bind(this)}
                        selectFirstOption={false}
                        theme={this.props.theme}
                    />
                    <FromAndToComponent
                        className={"newforma-formSpacing"}
                        isFromRequired={true}
                        isToRequired={true}
                        isCcRequired={false}
                        includeCc={true}
                        officeWrapper={this.props.officeWrapper}
                        disabled={this.state.isFiling}
                        formValidationHelpers={this.props.formValidationHelpers}
                        mailboxItem={this.props.mailboxItem}
                        onFromChange={this.onFromChange.bind(this)}
                        onToChange={this.onToChange.bind(this)}
                        onCcChange={this.onCcChange.bind(this)}
                        logger={this.props.logger}
                        projectsService={this.props.projectsService}
                        onError={this.props.onShowToast}
                        project={this.state.selectedProject}
                        shouldHideFrom={false}
                        onEmailCheck={this.onCCAndToComparison.bind(this)}
                    />
                    <Label required={false}>
                        {this.props.translate("SUBMITTALS.RESPONSE.REMARKS_LABEL") as string}
                    </Label>
                    {this.props.isFileTransferAndEditorSupported ? (
                        <HTMLEditor
                            value={this.state.remarks}
                            onRemarksUpdate={this.onRemarksChange.bind(this)}
                            configService={this.props.configService}
                            isFiling={this.state.isFiling}
                        />
                    ) : (
                        <TextField
                            className="newforma-formSpacing"
                            id="submittalReviewResponse-remarks"
                            multiline
                            resizable={true}
                            value={this.state.remarks}
                            onChange={this.onOldEditorRemarksChange.bind(this)}
                            disabled={this.state.isFiling}
                            rows={5}
                            maxLength={65000}
                        />
                    )}
                    {!this.isCloudProject() ? (
                        <>
                            <TranslatedDatePickerComponent
                                date={this.state.receivedDate}
                                label={this.props.translate("SUBMITTALS.RECEIVED_DATE") as string}
                                onDateChange={this.onReceivedDateChange.bind(this)}
                                className="newforma-subReviewResponseReceivedDate newforma-formSpacing"
                                disabled={this.state.isFiling}
                                required={true}
                                clearDateButtonVisibility={ElementVisibility.None}
                            />
                            <ChipPickerComponent
                                className="newforma-formSpacing newforma-submittalRevResponseKeywords"
                                required={false}
                                disabled={
                                    this.state.isFiling || this.state.isLoadingKeywords || !this.state.selectedProject
                                }
                                items={this.state.keywords}
                                selectedItems={this.state.selectedKeywords}
                                onSelectedItemsChanged={this.onKeywordsChanged.bind(this)}
                                label={this.props.translate("SUBMITTALS.KEYWORDS_LABEL") as string}
                                allowCustomInput={this.state.allowCustomKeywords}
                            />
                        </>
                    ) : null}
                    <AttachmentsComponent
                        attachments={this.state.attachments}
                        disabled={this.state.isFiling}
                        allowFileUploads={true}
                        onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                        isCloudProject={this.isCloudProject()}
                        logger={this.props.logger}
                        isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                        classNameForScroll={".submittalReviewResponseComponent"}
                    />
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("SHARED.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        id="fileAsSubmittalResponse"
                        primary={true}
                        onClick={this.onFormSubmit.bind(this)}
                        text={(this.props.translate("SUBMITTALS.RESPONSE.SUBMIT_BUTTON") as string).toLocaleUpperCase()}
                        disabled={!this.isFormValid()}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(SubmittalReviewResponseComponent);
