import { Checkbox, TooltipHost, DirectionalHint, ITooltipProps, TooltipDelay } from "office-ui-fabric-react";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import "./forwardCheckbox.less";

export interface ForwardCheckboxProps extends LocalizeContextProps {
    label: string;
    subtext: string;
    shouldForward: () => void;
    checked: boolean;
    isFiling: boolean;
    showDisableMessage?: boolean;
}

class ForwardCheckbox extends React.Component<ForwardCheckboxProps> {
    constructor(props: ForwardCheckboxProps) {
        super(props);
    }

    renderTooltipContent = () => (
        <div className="tooltip-content">
            {this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_START")}{" "}
            <span className="tooltip-bold">{this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_BOLD")}</span>{" "}
            {this.props.translate("SHARED.TOOLTIP.PROJECT_NOT_AVAILABLE_END")}
        </div>
    );

    render(): JSX.Element {
        const { label, subtext, shouldForward, checked, isFiling, showDisableMessage } = this.props;

        const tooltipProps: ITooltipProps = {
            onRenderContent: showDisableMessage ? this.renderTooltipContent : undefined,
            calloutProps: { className: "custom-tooltip-callout", gapSpace: 10 },
            delay: TooltipDelay.medium,
            directionalHint: DirectionalHint.topCenter,
        };

        return (
            <div className="newforma-checkboxContainer">
                <TooltipHost
                    tooltipProps={tooltipProps}
                    styles={{ root: { display: "inline-block", position: "relative" } }}
                >
                    <div>
                        <Checkbox
                            label={label}
                            onChange={shouldForward}
                            className={isFiling ? "checkboxFiling" : "checkbox"}
                            checked={checked}
                            disabled={isFiling}
                        />

                        <div
                            className={
                                isFiling
                                    ? "newforma-subtextCheckboxContainerFiling"
                                    : "newforma-subtextCheckboxContainer"
                            }
                        >
                            {subtext}
                        </div>
                    </div>
                </TooltipHost>
            </div>
        );
    }
}

export default withLocalize(ForwardCheckbox);
