import "core-js/stable";
import "regenerator-runtime/runtime";
import { initializeIcons } from "@uifabric/icons";
import "./styles.less";
import { OfficeRoamingSettings } from "./services/OfficeRoamingSettings";
import { EntryPoint } from "./services/EntryPoint";
import LoginDialog from "./components/login/LoginDialog";
import { AnalyticsManager } from "./services/AnalyticsManager";
import { NewformaApiClient } from "./services/NewformaApi/NewformaApiClient";
import { AWSSignatureVersion4Provider } from "./services/AWSSignatureVersion4Provider";
import { Authenticator } from "./services/Authenticator";
import { UrlBuilder } from "./services/UrlBuilder";
import { UnauthenticatedNewformaApiClient } from "./services/UnauthenticatedNewformaApiClient";
import { SmartFilingManager } from "./services/SmartFiling/SmartFilingManager";
import { ConfigurationService } from "./services/ConfigurationService";
import { OfficeWrapper } from "./services/OfficeWrapper";
import { AuthUrlProvider } from "./services/AuthUrlProvider";
import { HttpRequestWrapper } from "./services/HttpRequestWrapper";
import { OfficeNotificationService } from "./services/officeUi/OfficeNotificationService";
import { UserDisplayService } from "./services/UserDisplayService";
import { OutlookApiService } from "./services/OutlookApiService";
import { ActionItemApiService } from "./services/NewformaApi/ActionItemApiService";
import { FileUploadApiService } from "./services/NewformaApi/FileUploadApiService";
import { NrnServiceWrapper } from "./services/NrnServiceWrapper";
import { StorageWrapper } from "./services/StorageWrapper";
import { ConfigurationsApiService } from "./services/NewformaApi/ConfigurationsApiService";
import { WindowWrapper } from "./services/WindowWrapper";
import { FormValidationHelpers } from "./helpers/FormValidationHelpers";
import { TranslationService } from "./services/TranslationService";
import { Logger } from "./services/Logger";
import { CustomerSupportEmailService } from "./services/CustomerSupportEmailService";
import { ProjectsApiService } from "./services/NewformaApi/ProjectsApiService";
import { SendAndFileHelpers } from "./helpers/SendAndFile/SendAndFileHelpers";
import { DateHelpers } from "./helpers/DateHelpers";
import { SubmittalsApiService } from "./services/NewformaApi/SubmittalsApiService";
import { EulaApiService } from "./services/NewformaApi/EulaApiService";
import { RfiApiService } from "./services/NewformaApi/RfiApiService";
import { EmailApiService } from "./services/NewformaApi/EmailApiService";
import { MsGraphTokenService } from "./services/MsGraphTokenService";
import { MsGraphApiService } from "./services/MsGraphApiService";
import { GraphRequestWrapper } from "./services/GraphRequestWrapper";
import { ClearMessageService } from "./services/ClearMessageService";
import { AuthApiService } from "./services/NewformaApi/AuthApiService";
import { ProjectsCacheService } from "./services/NewformaApi/ProjectsCacheService";
import { IndexedDbHelper } from "./helpers/IndexedDbHelper";
import { InvalidateCacheService } from "./services/NewformaApi/InvalidateCacheService";
import { ProjectsRetryService } from "./services/NewformaApi/ProjectsRetryService";
import { RefreshProjectsBackgroundTask } from "./services/NewformaApi/RefreshProjectsBackgroundTask";
import { ProjectsPagingErrorService } from "./services/NewformaApi/ProjectsPagingErrorService";
import { Messenger } from "./services/Messenger";
import { IProjectsService } from "./services/NewformaApi/IProjectsService";
import { FileTransferApiService } from "./services/NewformaApi/FileTransferApiService";
import GainSight from "./services/GainSight";

initializeIcons(); // Function needed to initialize Fabric icons

// Extend global object using typescript declaration-merging
declare global {
    var __COMMIT_HASH__: String;
    var __BRANCH__: String;
    var __ENV__: String;
}

Office.initialize = () => {
    // NOTE: Keep - required. This also fires before .async()
};
// tslint:disable-next-line
(async () => {
    await Office.onReady();
    const logger = new Logger();
    const requestWrapper = new HttpRequestWrapper();
    const nrnServiceWrapper = new NrnServiceWrapper();
    const analyticsManager = new AnalyticsManager();
    const officeNotificationService = new OfficeNotificationService();
    const formValidationHelpers = new FormValidationHelpers();
    const userDisplayService = new UserDisplayService();
    const dateHelpers = new DateHelpers();
    const windowWrapper = new WindowWrapper(logger);
    const config = new ConfigurationService(logger);
    const storageWrapper = new StorageWrapper(logger);
    const officeWrapper = new OfficeWrapper(logger);
    const translationService = new TranslationService(logger);
    const aws = new AWSSignatureVersion4Provider(logger);
    const urlBuilder = new UrlBuilder(config);
    const officeRoamingSettings = new OfficeRoamingSettings(officeWrapper, logger);
    const loginDialog = new LoginDialog(officeWrapper, config, windowWrapper, logger);
    const outlookApiService = new OutlookApiService(officeWrapper, requestWrapper, windowWrapper, logger);
    const unauthenticatedNewformaApiClient = new UnauthenticatedNewformaApiClient(config, requestWrapper, logger);
    const smartFilingManager = new SmartFilingManager(officeRoamingSettings, officeWrapper, logger);
    const authUrlProvider = new AuthUrlProvider(
        unauthenticatedNewformaApiClient,
        urlBuilder,
        officeWrapper,
        storageWrapper,
        windowWrapper
    );
    const dbName = "newformaCache";
    const dbVersion = 1;
    const dbStoreName = "projectsStorage";
    const indexedDbHelper = new IndexedDbHelper(dbName, dbVersion, dbStoreName, logger);
    const maxCacheLifetimeMs = 14 * 24 * 3600000; // 14 days
    const invalidateCacheService = new InvalidateCacheService(
        indexedDbHelper,
        window.localStorage,
        logger,
        maxCacheLifetimeMs
    );
    const authenticator = new Authenticator(
        loginDialog,
        storageWrapper,
        unauthenticatedNewformaApiClient,
        authUrlProvider,
        officeWrapper,
        analyticsManager,
        logger
    );
    try {
        if (!authenticator.isValidUser()) {
            logger.info("User is invalid, calling authenticator.logoutFromApp()");
            authenticator.logoutFromApp();
        }
    } catch (error) {
        logger.error("Cannot validate the user", error);
    }
    const apiClient = new NewformaApiClient(config, aws, authenticator, logger);
    const fileUploadService = new FileUploadApiService(apiClient, requestWrapper, logger);
    const configurationsApiService = new ConfigurationsApiService(
        apiClient,
        unauthenticatedNewformaApiClient,
        requestWrapper,
        logger
    );
    const customerSupportEmailService = new CustomerSupportEmailService(logger, officeWrapper, windowWrapper, config);
    const projectsApiService = new ProjectsApiService(
        logger,
        apiClient,
        userDisplayService,
        requestWrapper,
        nrnServiceWrapper,
        officeRoamingSettings,
        storageWrapper
    );
    const emailApiService = new EmailApiService(
        logger,
        apiClient,
        requestWrapper,
        analyticsManager,
        officeRoamingSettings,
        officeWrapper,
        smartFilingManager
    );
    const actionItemApiService = new ActionItemApiService(
        apiClient,
        requestWrapper,
        outlookApiService,
        fileUploadService,
        nrnServiceWrapper,
        logger,
        emailApiService
    );
    const projectsPagingErrorService = new ProjectsPagingErrorService(projectsApiService, logger);
    const projectsRetryService = new ProjectsRetryService(projectsPagingErrorService, logger);
    const projectCacheService = new ProjectsCacheService(
        projectsRetryService,
        logger,
        indexedDbHelper,
        maxCacheLifetimeMs
    );
    const messenger = new Messenger();
    /* tslint:disable-next-line:no-floating-promises */
    clearLeftoversAndRefreshProjectCache(
        projectsRetryService,
        logger,
        indexedDbHelper,
        invalidateCacheService,
        messenger,
        officeRoamingSettings
    );
    const sendAndFileHelpers = new SendAndFileHelpers(officeWrapper, logger, projectCacheService);
    const submittalsApiService = new SubmittalsApiService(
        apiClient,
        requestWrapper,
        logger,
        outlookApiService,
        fileUploadService,
        analyticsManager,
        emailApiService,
        nrnServiceWrapper
    );
    const eulaApiService = new EulaApiService(apiClient, requestWrapper, logger);
    const rfiApiService = new RfiApiService(
        logger,
        apiClient,
        requestWrapper,
        analyticsManager,
        outlookApiService,
        fileUploadService,
        emailApiService,
        nrnServiceWrapper
    );
    const fileTransferService = new FileTransferApiService(
        logger,
        apiClient,
        requestWrapper,
        analyticsManager,
        outlookApiService,
        fileUploadService
    );
    const msGraphTokenService = new MsGraphTokenService(storageWrapper, apiClient, requestWrapper, logger);

    const graphRequestWrapper = new GraphRequestWrapper(requestWrapper, msGraphTokenService);
    const clearMessageService = new ClearMessageService();

    const msGraphApiService = new MsGraphApiService(officeWrapper, graphRequestWrapper, clearMessageService, logger);

    const authApiService = new AuthApiService(apiClient, requestWrapper);

    const entryPoint = new EntryPoint(
        authenticator,
        apiClient,
        unauthenticatedNewformaApiClient,
        analyticsManager,
        smartFilingManager,
        officeWrapper,
        officeRoamingSettings,
        officeNotificationService,
        config,
        actionItemApiService,
        storageWrapper,
        configurationsApiService,
        windowWrapper,
        formValidationHelpers,
        translationService,
        customerSupportEmailService,
        logger,
        projectCacheService,
        sendAndFileHelpers,
        dateHelpers,
        submittalsApiService,
        eulaApiService,
        rfiApiService,
        emailApiService,
        nrnServiceWrapper,
        msGraphApiService,
        authApiService,
        invalidateCacheService,
        messenger,
        fileTransferService,
        outlookApiService
    );

    if ((module as any).hot) {
        (module as any).hot.accept("./components/app/AppComponent", () => {
            entryPoint.render();
        });
    }
})();

async function clearLeftoversAndRefreshProjectCache(
    projectsService: IProjectsService,
    logger: Logger,
    indexedDbHelper: IndexedDbHelper,
    invalidateCacheService: InvalidateCacheService,
    messenger: Messenger,
    officeRoamingSettings: OfficeRoamingSettings
): Promise<void> {
    await invalidateCacheService.clearAllLeftovers();
    const maxCacheLifetimeForBackgroundRefreshMs = 3600000; // 1h
    const projectCacheForBackgroundService = new ProjectsCacheService(
        projectsService,
        logger,
        indexedDbHelper,
        maxCacheLifetimeForBackgroundRefreshMs
    );
    const cacheRefreshIntervalMs = 300000; // 5 minutes
    const refreshProjectsBackgroundService = new RefreshProjectsBackgroundTask(
        projectCacheForBackgroundService,
        logger,
        cacheRefreshIntervalMs,
        messenger,
        officeRoamingSettings
    );
    await refreshProjectsBackgroundService.init();
}

export * from "./index";
