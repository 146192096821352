import { Log } from "./Logger";
import { OfficeWrapper } from "./OfficeWrapper";
import { createHash } from "crypto";

const BASE_URL = "https://web-sdk.aptrinsic.com/api/aptrinsic.js";
const SCRIPT_ELEMENT_ID = "gainSightScript";
const gsProdProductKey = "AP-32L72UURH765-2";
const gsDevProductKey = "AP-32L72UURH765-2-2";

declare var aptrinsic: any;

interface Aptrinsic {
    (...params: unknown[]): void;
    q?: unknown[][];
    p?: string;
    c?: {
        iframeModeEnabled: boolean;
    };
}

interface GainSightWindow extends Window {
    aptrinsic?: Aptrinsic;
}

const GainSightWindow = (window as unknown) as GainSightWindow;

const tmStart: number = new Date().getTime();
export default class GainSight {
    gainSightProductKey: string = gsProdProductKey;
    userEmail: string = "";
    userName: string = "";
    userDomain: string = "";

    constructor(private officeWrapper: OfficeWrapper) {
        this.log("GainSight constructor");
        if (typeof GainSightWindow === "undefined") {
            return;
        }

        this.userEmail = this.officeWrapper.userProfileEmailAddress;
        this.userName = this.officeWrapper.userProfileDisplayName;
        this.userDomain = this.officeWrapper.userProfileEmailDomain;
        // include a domain check so that internal users don't distort analytics
        // this will override the PROD tag
        if (this.userDomain.includes("newforma") || this.userDomain.includes("newforming")) {
            this.gainSightProductKey = gsDevProductKey;
        }

        GainSightWindow.aptrinsic =
            GainSightWindow.aptrinsic ||
            ((...params: unknown[]): void => {
                GainSightWindow.aptrinsic?.q?.push(params);
            });

        GainSightWindow.aptrinsic.q = GainSightWindow.aptrinsic.q || [];
        GainSightWindow.aptrinsic.p = this.gainSightProductKey;
        GainSightWindow.aptrinsic.c = { iframeModeEnabled: false };

        aptrinsic = GainSightWindow.aptrinsic;

        this.log("Gainsight created!");
    }

    getElapsed(): string {
        return `${Date.now() - tmStart} ms`;
    }

    identify(msg: string): boolean {
        this.log(msg);

        if (typeof aptrinsic !== "function") {
            return false;
        }

        const user = this.getUser();
        const account = this.getAccount();

        try {
            aptrinsic("identify", user, account);

            return true;
        } catch (error) {
            return false;
        }
    }

    retryAptrinsicIdentify(delay: number, maxAttempts: number) {
        return new Promise((resolve, reject) => {
            let attempts = 0;

            const attempt = () => {
                attempts++;

                const result = this.identify(`'identify' call ${attempts}`);
                if (result) {
                    resolve(result);
                } else {
                    if (attempts < maxAttempts) {
                        setTimeout(attempt, delay);
                    } else {
                        reject(new Error("'identify' failed"));
                    }
                }
            };

            attempt();
        });
    }

    initialize(): HTMLScriptElement {
        this.log("GainSight initialization");

        const scriptElement = document.createElement("script");
        /*
        'defer': This tells the browser to download the script asynchronously but execute it only after the HTML parsing is complete. 
        */
        scriptElement.id = SCRIPT_ELEMENT_ID;
        scriptElement.async = true;
        scriptElement.defer = true;
        scriptElement.src = `${BASE_URL}?a=${this.gainSightProductKey}`;

        // this handler is supposed to execute when the script is fully loaded..
        // but this may not be totally true.. so a small delay has been added along with retry..
        scriptElement.onload = () => {
            // Code to execute after the script is loaded
            this.log(`GainSight calling 'identify'`);

            this.retryAptrinsicIdentify(50, 10)
                .then((result) => {
                    // Handle the successful result
                    this.log(`'identify' Success: ${result}`);
                })
                .catch((error) => {
                    // Handle the error
                    this.log(`'identify' Error: ${error}`);
                });
        };

        const firstScriptElement = document.getElementsByTagName("script")[0];
        const parentNode = firstScriptElement?.parentNode ?? document.head ?? document.body;

        parentNode.insertBefore(scriptElement, firstScriptElement);
        this.log(`GainSight script inserted`);
        return scriptElement;
    }

    getUser(): any {
        const userId = this.generateGUIDFromEmail(this.userEmail);
        const [firstName, lastName] = this.userName.split(" ");

        const result = { id: userId, email: this.userEmail, firstName, lastName };
        this.log(`User :${JSON.stringify(result)}`);

        return result;
    }

    getAccount(): any {
        const accountId = this.generateGUIDFromDomain(this.userDomain);
        const result = { id: accountId, name: this.userDomain };

        this.log(`Account :${JSON.stringify(result)}`);
        return result;
    }

    generateGUIDFromEmail(email: any) {
        // Convert the email to lowercase and remove special characters
        const sanitizedEmail = email.toLowerCase().replace(/[^a-z0-9@.]/g, "");
        return this.generateGUIDFromString(sanitizedEmail);
    }

    generateGUIDFromDomain(domain: any) {
        // Convert the domain to lowercase and remove special characters
        const sanitizedDomain = domain.toLowerCase().replace(/[^a-z0-9.]/g, "");
        return this.generateGUIDFromString(sanitizedDomain);
    }

    // Use a cryptographic hash function (e.g., SHA-256) to generate a hash
    // an use only the first 32 chars

    generateGUIDFromString(value: any) {
        const hash = createHash("sha256");
        hash.update(value);
        // Extract a portion of the hash as the GUID
        const guid = hash.digest("hex").substring(0, 32);

        return guid;
    }

    log(msg: any) {
        console.log(`[GS ${this.getElapsed()}] ${msg}`);
    }
}
