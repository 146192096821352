import "./FileTransferComponent.less";
import * as React from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger, Log } from "../../services/Logger";
import {
    DefaultButton,
    IPersonaProps,
    ITag,
    ProgressIndicator,
    MessageBarType,
    MessageBar,
    TextField,
    Panel,
    Icon,
    Separator,
    Stack,
    Label,
} from "office-ui-fabric-react";
import AttachmentsComponent from "../shared/attachments/AttachmentsComponent";
import { MailboxItem, OfficeWrapper } from "../../services/OfficeWrapper";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { FormValidationHelpers } from "../../helpers/FormValidationHelpers";
import { AnalyticsManager } from "../../services/AnalyticsManager";
import { LocalStorageKeys, ProjectsCacheKeys } from "../../models/StorageKeys";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import FromAndToComponent from "../shared/fromAndToComponent/FromAndToComponent";
import MoreOptionsComponent, { isOnDatePick } from "../shared/moreOption/MoreOptionsComponent";
import MessageDialogComponent from "../shared/MessageDialogComponent";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import ChipPickerComponent from "../shared/chipPicker/ChipPickerComponent";
import { KeywordListType, DetailedKeyword as ProjectKeyword } from "../../models/ProjectKeywordsResponse";
import KeywordsDropdown from "../shared/keywordsDropdown/KeywordsDropdown";
import { DateOffsetType } from "../../models/projects/ProjectSettingsResponse";
import { DateHelpers, DateHelper } from "../../helpers/DateHelpers";
import { ProjectHelper } from "../../helpers/ProjectHelper";
import { FileTransferApiService } from "../../services/NewformaApi/FileTransferApiService";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import { LogFileTransferParams } from "../../models/workflow/fileTransfer/LogFileTransferParams";
import { Keyword } from "../../models/Keyword";
import { ReminderOptions, TransferSettingsProps } from "../../models/shared/FileTransferSettingsProps";
import { AttachmentItem } from "../../models/shared/AttachmentList";
import { OutlookApiService } from "../../services/OutlookApiService";
import { mapAttachmentsMetadataToAttachment } from "../../helpers/SendAndFile/AttachmentHelpers";
import { StorageWrapper } from "../../services/StorageWrapper";
import { EmailRecipients } from "../../models/shared/FileTransferEmailFieldsDetails";
import { MoreOptionsDetails, MoreOptionsDefaultReminder } from "../../models/shared/FileTransferMoreOptionsDetails";
import HTMLEditor from "../shared/editor/Editor";
import { ConfigurationService } from "../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../helpers/AttachmentDataHelpers";

export interface FileTransferComponentProps extends LocalizeContextProps {
    logger: Logger;
    onDismiss: () => void;
    showPanel: boolean;
    onExpiredSession: () => void;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    projectsService: IProjectsService;
    mailboxItem: MailboxItem | null;
    analyticsManager: AnalyticsManager;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    theme: string;
    dateHelpers: DateHelpers;
    fileTransferApiService: FileTransferApiService;
    invalidateCacheService: InvalidateCacheService;
    fileTransferService: FileTransferApiService;
    outlookApiService: OutlookApiService;
    storageWrapper: StorageWrapper;
    isFilePathInAttachmentsSupported?: boolean;
    changeAppHeaderText: (toastText: string, toastType: MessageBarType) => void;
    onDismissToastMessage: () => void;
    appToastMessage: string | undefined;
    appToastType: MessageBarType | undefined;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
}

export interface FileTransferComponentState {
    projects: ITag[];
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    attachments: AttachmentItem[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    from: IPersonaProps[];
    remarks: string;
    fileUploadCompletedCount: number;
    fileUploadCount: number;
    failedUploadAttachmentNames: string[];
    subject: string;
    isFileTransferInProgress: boolean;
    isCancelInProgress: boolean;
    isCancelCorrect: boolean;
    shouldClearEmails: boolean;
    theme: string;
    selectedKeywords: IPersonaProps[];
    purposes: ProjectKeyword[];
    isLoadingKeywords: boolean;
    selectedPurpose: ProjectKeyword | null;
    dueDate: Date | undefined;
    receivedDate: Date | undefined;
    isLoading: boolean;
    projectSettings: TransferSettingsProps;
    isSettingsLoading: boolean;
    isClearForm: boolean;
    showPurposeField: boolean;
    toastType?: MessageBarType;
    toastMessage?: string;
    shouldSetReminder?: boolean;
    shouldPersistSelectedValues?: boolean;
    emailRecipients?: EmailRecipients;
    moreOptionsDetails: MoreOptionsDetails;
    shouldCollapseMoreOptions?: boolean;
    areToAndCCSame: boolean;
}

export const fileTransferComponentDefaultState: FileTransferComponentState = {
    projects: [],
    selectedProject: null,
    isLoadingProjects: true,
    attachments: [],
    to: [],
    cc: [],
    from: [],
    remarks: "",
    fileUploadCompletedCount: 0,
    fileUploadCount: 0,
    failedUploadAttachmentNames: [],
    subject: "",
    isFileTransferInProgress: false,
    isCancelInProgress: false,
    isCancelCorrect: false,
    shouldClearEmails: false,
    theme: "",
    selectedKeywords: [],
    purposes: [],
    isLoadingKeywords: true,
    selectedPurpose: null,
    dueDate: undefined,
    receivedDate: undefined,
    isLoading: false,
    projectSettings: {
        allowPartialDownload: false,
        defaultExpireDays: 0,
        enabled: false,
        requireNixLoginForDownload: false,
        transferExpiresByDefault: true,
        transferWithTransmittal: true,
        reminderObject: { key: 0, text: "", days: 1 },
    },
    isSettingsLoading: false,
    isClearForm: false,
    showPurposeField: false,
    shouldSetReminder: true,
    shouldPersistSelectedValues: false,
    emailRecipients: {
        to: [],
        cc: [],
    },
    moreOptionsDetails: {
        allowPartialDownload: false,
        expiresOn: undefined,
        reminderOn: undefined,
        requireNixLoginForDownload: false,
        transferWithTransmittal: false,
    },
    shouldCollapseMoreOptions: false,
    areToAndCCSame: false,
};

class FileTransferComponent extends React.Component<FileTransferComponentProps, FileTransferComponentState> {
    defaultReminderObject: MoreOptionsDefaultReminder;
    private container: HTMLElement | null = null;

    constructor(props: FileTransferComponentProps, context: FileTransferComponentState) {
        super(props, context);

        this.defaultReminderObject = {
            key: 0,
            text: this.props.translate("SHARED.MOREOPTIONS.DROPDOWN_PLACEHOLDER_REMINDER") as string,
            days: 1,
        };
        fileTransferComponentDefaultState.projectSettings.reminderObject = this.defaultReminderObject;

        this.state = fileTransferComponentDefaultState;
    }

    async componentDidUpdate(
        prevProps: Readonly<FileTransferComponentProps>,
        prevState: Readonly<FileTransferComponentState>,
        snapshot?: any
    ) {
        if (this.props.appToastMessage !== prevProps.appToastMessage && this.props.appToastMessage) {
            this.setState({ toastMessage: this.props.appToastMessage, toastType: this.props.appToastType });
        }
        if (
            this.state.projectSettings.transferExpiresByDefault &&
            this.state.projectSettings.reminderObject?.days === 0 &&
            this.state.selectedProject &&
            !this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.reminderCheckedValue)
        ) {
            const savedReminderDate = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.reminderDate);
            const savedReminderCalendarDate = this.props.storageWrapper.loadLocalStorage(
                LocalStorageKeys.reminderCalendarDate
            );
            const projectSettingsResponse: TransferSettingsProps = this.state.projectSettings;
            if (savedReminderDate) {
                const savedReminderValue = JSON.parse(savedReminderDate);
                if (isOnDatePick(savedReminderValue.key) && savedReminderCalendarDate) {
                    const numberOfDays = JSON.parse(savedReminderCalendarDate);
                    projectSettingsResponse.reminderDate = this.props.dateHelpers.getDateWithOffset(
                        DateHelper.TodayDate,
                        Math.ceil(numberOfDays)
                    );
                } else {
                    projectSettingsResponse.reminderObject = {
                        key: savedReminderValue.key,
                        text: savedReminderValue.text,
                        days: savedReminderValue.days,
                    };
                }
            } else {
                projectSettingsResponse.reminderObject = this.defaultReminderObject;
            }
            this.shouldSetReminderDate();
        }

        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            // resets the state back to default, and then uses 'some' values of the current state to carry over to the new state.
            Log.info(`mailboxItem change`);

            this.setState((state) => ({
                ...fileTransferComponentDefaultState,
                projects: state.projects,
                selectedProject: state.selectedProject,
                to: state.to,
                cc: state.cc,
                from: state.from,
                fileUploadCount: state.fileUploadCount,
                fileUploadCompletedCount: state.fileUploadCompletedCount,
                isFileTransferInProgress: state.isFileTransferInProgress,
            }));

            await this.populateDefaults();
            if (prevState?.selectedProject) {
                await Promise.all([
                    this.loadKeywords(prevState.selectedProject),
                    this.getProjectSettings(prevState.selectedProject),
                ]);
            }
            this.setState({
                isLoadingProjects: false,
                selectedProject: prevState.selectedProject,
            });
        }
        this.addOrRemoveExpandableClass();
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true, isSettingsLoading: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingFileTransfer();
            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }
            this.setState({
                projects: [],
            });
            return this.onShowToast(
                this.props.translate("APP.FILE_TRANSFER.ERROR") as string,
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private async populateDefaults(): Promise<void> {
        Log.info("Populating file transfer form with defaults");
        this.setState({ isLoading: true });
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();
        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);
        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );
        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();
        const dueDate = DateHelper.getDateWithOffset(receivedDate, 14);
        const remarks = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );
        const subject =
            (await this.props.officeWrapper.getCurrentEmailSubject()) ||
            (this.props.translate("APP.FILE_TRANSFER.NO_SUBJECT") as string);
        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(remarks, attachmentDetails);

        this.setState({
            attachments: attachmentsNoCloud,
            remarks: this.props.isFileTransferAndEditorSupported ? htmlBody?.trim() : remarks?.trim(),
            subject: subject?.trim(),
            receivedDate,
            dueDate,
        });
        this.setState({ isLoading: false });
    }
    private async getProjectSettings(project: ITag) {
        this.setState({ isSettingsLoading: true });
        try {
            const projectSettings = await this.props.projectsService.getProjectSettings(project.key as string);
            const savedExpiredDate = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.expireDate);
            const savedReminderDate = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.reminderDate);
            const savedExpireCalendarDate = this.props.storageWrapper.loadLocalStorage(
                LocalStorageKeys.expireCalendarDate
            );
            const savedReminderCalendarDate = this.props.storageWrapper.loadLocalStorage(
                LocalStorageKeys.reminderCalendarDate
            );
            const savedTransferChecked = this.props.storageWrapper.loadLocalStorage(
                LocalStorageKeys.transferExpiresCheckedValue
            );
            const savedReminderChecked = this.props.storageWrapper.loadLocalStorage(
                LocalStorageKeys.reminderCheckedValue
            );
            const todayDate = DateHelper.TodayDate;

            if (!projectSettings) {
                const dueDate = DateHelper.getDateWithOffset(this.state.receivedDate as Date, 14);
                this.setState({
                    dueDate: dueDate,
                    projectSettings: {
                        allowPartialDownload: false,
                        enabled: false,
                        requireNixLoginForDownload: false,
                        transferExpiresByDefault: true,
                        transferWithTransmittal: true,
                        reminderObject: this.defaultReminderObject,
                    },
                    showPurposeField: true,
                });
                return;
            }
            const projectSettingsResponse: TransferSettingsProps = projectSettings?.transfer;
            projectSettingsResponse.isNixLoginForDownload = projectSettingsResponse.requireNixLoginForDownload ?? false;
            if (savedExpiredDate && !projectSettings?.transfer.transferExpiresByDefault) {
                const savedExpiredValue = JSON.parse(savedExpiredDate);
                if (isOnDatePick(savedExpiredValue.key) && savedExpireCalendarDate) {
                    const numberOfDays = JSON.parse(savedExpireCalendarDate);
                    projectSettingsResponse.expireDate = DateHelper.getDateWithOffset(todayDate, numberOfDays);
                } else {
                    projectSettingsResponse.defaultExpireDays = savedExpiredValue.days;
                }
            }
            // need this check so if NPC setting for expiration is set, localStorage is updated
            if (projectSettings?.transfer.transferExpiresByDefault) {
                this.props.storageWrapper.saveLocalStorage(
                    LocalStorageKeys.transferExpiresCheckedValue,
                    JSON.stringify(true)
                );
            }
            if (savedReminderDate) {
                const savedReminderValue = JSON.parse(savedReminderDate);
                if (isOnDatePick(savedReminderValue.key) && savedReminderCalendarDate) {
                    const numberOfDays = JSON.parse(savedReminderCalendarDate);
                    projectSettingsResponse.reminderDate = DateHelper.getDateWithOffset(todayDate, numberOfDays);
                } else {
                    projectSettingsResponse.reminderObject = {
                        key: savedReminderValue.key,
                        text: savedReminderValue.text,
                        days: savedReminderValue.days,
                    };
                }
            } else {
                projectSettingsResponse.reminderObject = this.defaultReminderObject;
            }
            if (
                !projectSettingsResponse.transferExpiresByDefault &&
                (!savedExpiredDate || savedTransferChecked !== "true")
            ) {
                projectSettingsResponse.expireDate = undefined;
                projectSettingsResponse.shouldClearExpiredDate = true;
            }
            if (
                !projectSettingsResponse.transferExpiresByDefault &&
                (!savedReminderDate || savedReminderChecked !== "true")
            ) {
                projectSettingsResponse.shouldClearReminderDate = true;
            }
            projectSettingsResponse.hasDefaultZeroValue = !projectSettingsResponse.transferExpiresByDefault
                ? true
                : false;
            this.setState({
                dueDate: this.getDueDate(projectSettings.transfer.defaultExpireDays, DateOffsetType.business),
                projectSettings: projectSettingsResponse,
                showPurposeField: projectSettingsResponse.transferWithTransmittal === true ? true : false,
            });
        } catch (error) {
            this.handleApiError(error, this.props.translate("SHARED.ERRORS.FAILED_LOADING_PROJECT_SETTINGS") as string);
        } finally {
            this.setState({ isSettingsLoading: false });
        }
    }

    private getDueDate(dateOffset: number | undefined, offsetType: DateOffsetType): Date | undefined {
        if (!this.state.receivedDate) {
            return undefined;
        }

        const offset = dateOffset === undefined ? 14 : dateOffset;
        return offsetType === DateOffsetType.business
            ? DateHelper.addBusinessDays(this.state.receivedDate, offset)
            : DateHelper.getDateWithOffset(this.state.receivedDate, offset);
    }
    private handleApiError(error: any, displayMessage: string): void {
        Log.error(`FileTransferComponent API error: ${displayMessage}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }
        if (error.level === undefined) {
            this.onShowToast(displayMessage, MessageBarType.severeWarning).then;
            return;
        }

        if (error.status === 405) {
            this.onShowToast(
                this.props.translate("APP.FILE_TRANSFER.PERMISSIONS_INSUFFICIENT") as string,
                MessageBarType.severeWarning
            ).then;
            return;
        } else if (error.status === 400 && error.message.includes("not valid keywords for Transmittal Keywords")) {
            this.onShowToast(
                this.props.translate("APP.FILE_TRANSFER.VALIDATION_ERROR_INVALID_KEYWORDS") as string,
                MessageBarType.severeWarning
            ).then;
            return;
        } else {
            this.onShowToast(this.props.translate("APP.FILE_TRANSFER.ERROR") as string, MessageBarType.severeWarning)
                .then;
            return;
        }
    }

    private onKeywordsChange(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }
    private clearKeywords(): void {
        this.setState({
            selectedKeywords: [],
            selectedPurpose: null,
        });
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        if (!this.state.shouldPersistSelectedValues) {
            this.clearKeywords();
        }
        this.setState({ selectedProject: selectedProject, isClearForm: false, isSettingsLoading: true });
        if (selectedProject) {
            await Promise.all([this.loadKeywords(selectedProject), this.getProjectSettings(selectedProject)]);
            this.setState({ isSettingsLoading: false });
        }
    }

    private async loadKeywords(project: ITag): Promise<void> {
        this.setState({ isLoadingKeywords: true });
        Log.info(`Loading keywords for selected project: ${project.name}`);
        try {
            const purposesResponse = await this.props.projectsService.getProjectKeywords(
                project.key,
                KeywordListType.TransmittalPurposes
            );
            this.setState({
                purposes: ProjectHelper.sortKeywords(purposesResponse),
            });
        } catch (error) {
            this.clearKeywords();

            this.handleKeywordError(error);
        } finally {
            this.setState({ isLoadingKeywords: false });
        }
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({ subject: newValue || "" });
    }

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
    }

    private async onRefresh(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.fileTransferCacheName);
        return this.loadProjects();
    }

    private async onPanelOpen(): Promise<void> {
        this.props.logger.info("File Transfer component mounted"); // used by unittests do not change
        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".ms-Panel-content")?.scrollTo({
            top: document.querySelector(".ms-Panel-content")?.scrollHeight,
            behavior: "auto",
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    private isFormValid(): boolean {
        return !!(
            this.state.selectedProject &&
            this.props.formValidationHelpers.areAssigneesValid(this.state.to, true) &&
            this.state.subject &&
            (!this.state.showPurposeField || this.state.selectedPurpose) &&
            this.state.attachments.length > 0 &&
            !this.state.areToAndCCSame
        );
    }

    private onToChange(items?: IPersonaProps[]): void {
        this.setState({ to: items || [], shouldClearEmails: false });
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState({ cc: people || [], shouldClearEmails: false });
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onClearForm(): void {
        this.setState(
            {
                shouldClearEmails: true,
                isClearForm: true,
                selectedProject: null,
                attachments: [],
                to: [],
                cc: [],
                remarks: "",
                subject: "",
                selectedKeywords: [],
                purposes: [],
                selectedPurpose: null,
            },
            () => {
                this.onExpandMoreOptions();
            }
        );
    }

    getElement(selector: string): HTMLElement {
        // Select the element using querySelector
        return document.querySelector(selector) as HTMLElement;
    }

    onExpandMoreOptions() {
        Log.info(`Project ${this.state.selectedProject?.name ?? "empty"}`);
        const moreOptions: HTMLElement = this.getElement(".ms-Panel-content");
        if (this.state.selectedProject) {
            if (moreOptions) {
                const submitBtn: HTMLElement = this.getElement(".newforma-footer");
                const subHeight = submitBtn?.offsetHeight;

                const moreHeight = moreOptions.offsetHeight;

                moreOptions.scrollTo(0, moreHeight ?? 0 + subHeight ?? 0);
            }
        }
    }

    // this is needed to add/remove class to element in panel
    private addOrRemoveExpandableClass(): void {
        const toEmailField = document.querySelector(".newforma-fileTransfer .newforma-toPicker");
        if (this.state.to.length >= 3) {
            toEmailField?.classList.add("expandable");
            toEmailField?.classList.remove("notExpandable");
        } else {
            toEmailField?.classList.add("notExpandable");
            toEmailField?.classList.remove("expandable");
        }

        const ccEmailField = document.querySelector(".newforma-fileTransfer .newforma-ccPicker");
        if (this.state.cc.length >= 3) {
            ccEmailField?.classList.add("expandable");
            ccEmailField?.classList.remove("notExpandable");
        } else {
            ccEmailField?.classList.add("notExpandable");
            ccEmailField?.classList.remove("expandable");
        }
    }
    private onPurposeSelectionChange(option: ProjectKeyword | null): void {
        this.setState({ selectedPurpose: option });
    }
    private handleKeywordError(error: any): void {
        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        this.onShowToast(
            this.props.translate("APP.FILE_TRANSFER.LOADING_KEYWORDS_FAILED") as string,
            MessageBarType.severeWarning
        ).then;
    }
    fileUploadCallback(isInProgress: boolean, failedIds: string[]): void {
        Log.info(`fileUploadCallBack ${isInProgress}`);
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            isFileTransferInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getExpireDate(numberOfDays: number): Date {
        return DateHelper.getDateWithOffset(DateHelper.TodayDate, numberOfDays);
    }

    private handleProgressEvent() {
        this.setState({ fileUploadCompletedCount: this.state.fileUploadCompletedCount + 1 });
    }

    getValidatedReminderDate(
        expiredDate: Date | undefined,
        reminderObject: ReminderOptions | undefined,
        reminderProjectSettingsDate: Date | undefined,
        isExpiredChecked: Boolean | undefined
    ) {
        let reminderDate;
        if (reminderObject) {
            const todayDate = DateHelper.TodayDate;

            const theDays = reminderObject.days ?? 0;
            // ** this test will need to change to be language insensitive
            const theKey = reminderObject.key ?? 0;

            const isBusiness = theKey > 0 && theKey <= 22;
            const isBefore = theKey > 0 && theKey <= 10;

            const refDate = isBefore && isExpiredChecked ? expiredDate : todayDate;

            reminderDate = refDate
                ? DateHelper.addDays(refDate, isBefore && isExpiredChecked ? -theDays : theDays, isBusiness)
                : undefined;
            if (expiredDate && reminderDate && isExpiredChecked && reminderDate?.getTime() >= expiredDate.getTime()) {
                const areThereBusinessDays = DateHelper.areThereBusinessDaysBetweenDateAndToday(expiredDate);
                reminderDate = DateHelper.addDays(expiredDate, -1, areThereBusinessDays);
            }

            if (reminderDate) {
                const diffDays = DateHelper.subDate(todayDate, expiredDate);
                // test to ensure that reminder 'comes later than' today, but can't happen on expiredate
                if (
                    (diffDays > 3 && reminderDate.getTime() <= todayDate.getTime()) ||
                    DateHelper.isSameDay(reminderDate, todayDate)
                ) {
                    reminderDate = DateHelper.addDays(todayDate, 1);
                }
                this.state.projectSettings.reminderDate = reminderDate;
            }
            // updates the reminder date correctly when there are no business days between today and the expiration date
            if (
                this.state.projectSettings.reminderDate &&
                expiredDate &&
                this.state.projectSettings.reminderDate.getTime() > expiredDate.getTime() &&
                isExpiredChecked
            ) {
                const areThereBusinessDays = DateHelper.areThereBusinessDaysBetweenDateAndToday(expiredDate);
                const accurateDate = DateHelper.addDays(expiredDate, -1, areThereBusinessDays);
                this.state.projectSettings.reminderDate = accurateDate;
                reminderDate = accurateDate;
            }
        } else if (expiredDate) {
            // adjust reminderDate when expiration value is before reminder calendar date
            if (
                reminderProjectSettingsDate &&
                reminderProjectSettingsDate.getTime() >= expiredDate.getTime() &&
                isExpiredChecked
            ) {
                reminderDate = DateHelper.addDays(expiredDate, -1, true);
                this.state.projectSettings.reminderDate = reminderDate;
            }
        }
        return reminderDate;
    }

    updateReminderDates(): void {
        const savedReminderDate = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.reminderDate);
        const savedReminderCalendarDate = this.props.storageWrapper.loadLocalStorage(
            LocalStorageKeys.reminderCalendarDate
        );
        if (savedReminderDate) {
            const savedReminderValue = JSON.parse(savedReminderDate);
            if (isOnDatePick(savedReminderValue.key) && savedReminderCalendarDate) {
                // if reminderDate state value was updated to be tomorrow, then use that value, otherwise use sticky value
                if (
                    this.state.projectSettings.reminderDate &&
                    DateHelper.isSameDay(
                        this.state.projectSettings.reminderDate,
                        DateHelper.addDays(DateHelper.TodayDate, 1)
                    )
                ) {
                    return;
                } else {
                    const numberOfDays = JSON.parse(savedReminderCalendarDate);
                    this.state.projectSettings.reminderDate = DateHelper.getDateWithOffset(
                        DateHelper.TodayDate,
                        numberOfDays
                    );
                }
            } else {
                this.state.projectSettings.reminderObject = {
                    key: savedReminderValue.key,
                    text: savedReminderValue.text,
                    days: savedReminderValue.days,
                };
                // updates reminder date based on days in object
                if (savedReminderValue.key >= 13 && savedReminderValue.key <= 23) {
                    this.state.projectSettings.reminderDate = DateHelper.addDays(
                        DateHelper.TodayDate,
                        savedReminderValue.days,
                        true
                    );
                }
            }
        } else {
            this.state.projectSettings.reminderObject = this.defaultReminderObject;
        }
    }

    shouldSetReminderDate(): void {
        const savedReminderChecked = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.reminderCheckedValue);
        if (savedReminderChecked) {
            const savedReminderCheckedValue = JSON.parse(savedReminderChecked);
            this.setState({ shouldSetReminder: savedReminderCheckedValue });
        }
    }

    shouldSetExpiredDate(): Boolean {
        const savedExpiredChecked = this.props.storageWrapper.loadLocalStorage(
            LocalStorageKeys.transferExpiresCheckedValue
        );
        if (savedExpiredChecked) {
            const savedExpiredCheckedValue = JSON.parse(savedExpiredChecked);
            return savedExpiredCheckedValue;
        }
        return false;
    }
    private saveLocalStorage(key: LocalStorageKeys, value: any) {
        if (value === undefined) {
            return;
        }
        this.props.storageWrapper.saveLocalStorage(key, JSON.stringify(value));
    }

    private async onFormSubmit(): Promise<void> {
        this.container = document.querySelector(".ms-Panel-contentInner");
        this.props.logger.info("file transfer form submitted"); // used by unittest , do not change
        const { mapPersonaPropsToContact } = this.props.formValidationHelpers;

        const { projectSettings: Settings } = this.state;
        const attachments = this.state.attachments;
        const loggedInUser = this.props.storageWrapper.loadLocalStorage(LocalStorageKeys.currentLoggedEmail);
        const fromUserPersona = loggedInUser ? [{ text: loggedInUser }] : [];
        this.setState({
            toastMessage: undefined,
            toastType: undefined,
            isFileTransferInProgress: true,
            fileUploadCompletedCount: 1,
            fileUploadCount: attachments.length,
            shouldPersistSelectedValues: false,
            emailRecipients: { to: [], cc: [] },
            moreOptionsDetails: {
                allowPartialDownload: false,
                expiresOn: undefined,
                reminderOn: undefined,
                requireNixLoginForDownload: false,
                transferWithTransmittal: false,
            },
        });

        try {
            DateHelper.synchNow();

            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const selectedPurpose = this.state.selectedPurpose as Keyword;
            const attachmentsDetails = await this.props.outlookApiService.getFileAttachmentDetailsMetadata(
                attachments,
                this.props.isFileTransferAndEditorSupported
            );
            const attachmentFilesList = mapAttachmentsMetadataToAttachment(
                attachmentsDetails,
                this.props.isFileTransferAndEditorSupported
            );
            const theExpireDays = Settings?.defaultExpireDays ?? 14;

            const expiredDate = Settings.expireDate ?? this.getExpireDate(theExpireDays);

            this.updateReminderDates();
            this.shouldSetReminderDate();
            const isExpirationChecked = this.shouldSetExpiredDate();
            const reminderDate = this.getValidatedReminderDate(
                expiredDate,
                Settings.reminderObject,
                Settings.reminderDate,
                isExpirationChecked
            );
            Log.info(`-- TodayDate ${DateHelper.TodayDate}`);
            Log.info(`-- RemindDate ${reminderDate}`);
            Log.info(`-- ExpireDate ${expiredDate}`);

            const { formValidationHelpers: formValidation } = this.props;

            const determinedReminderDate =
                this.state.shouldSetReminder && !Settings.shouldClearReminderDate
                    ? Settings.reminderDate
                        ? DateHelper.adjustTime(Settings.reminderDate)
                        : reminderDate
                    : undefined;

            const determinedExpirationDate = !Settings.shouldClearExpiredDate
                ? Settings.expireDate
                    ? DateHelper.adjustTime(Settings.expireDate)
                    : expiredDate
                : undefined;
            if (Settings.defaultExpireDays !== 0 && Settings.defaultExpireDays) {
                this.saveLocalStorage(LocalStorageKeys.npcProjectExpiredDate, determinedExpirationDate);
            }
            const to = mapPersonaPropsToContact(this.state.to);
            const cc = mapPersonaPropsToContact(this.state.cc);
            const from = mapPersonaPropsToContact(fromUserPersona)[0];

            const fileTransferParams: LogFileTransferParams = {
                from,
                to,
                cc,
                subject: this.state.subject,
                remarks: this.state.remarks,
                purpose: this.state.showPurposeField
                    ? {
                          name: selectedPurpose?.name,
                          type: selectedPurpose?.type,
                      }
                    : undefined,
                keywords: formValidation.mapPersonaPropsToKeywords(this.state.selectedKeywords),
                expiresOn: determinedExpirationDate,
                reminderOn: determinedReminderDate,
                allowPartialDownload: Settings.allowPartialDownload,
                requireNixLoginForDownload: Settings.requireNixLoginForDownload,
                transferWithTransmittal: Settings.transferWithTransmittal,
                attachments: attachmentFilesList,
            };
            this.setState({
                moreOptionsDetails: {
                    expiresOn: !Settings.shouldClearExpiredDate
                        ? Settings.expireDate
                            ? DateHelper.adjustTime(Settings.expireDate)
                            : expiredDate
                        : undefined,
                    reminderOn:
                        this.state.shouldSetReminder && !Settings.shouldClearReminderDate
                            ? Settings.reminderDate
                                ? DateHelper.adjustTime(Settings.reminderDate)
                                : reminderDate
                            : undefined,
                    allowPartialDownload: Settings.allowPartialDownload,
                    requireNixLoginForDownload: Settings.requireNixLoginForDownload,
                    transferWithTransmittal: Settings.transferWithTransmittal,
                },
                shouldPersistSelectedValues: true,
                emailRecipients: { to: this.state.to, cc: this.state.cc },
            });
            Log.info("fileAsFileTransfer started");
            await this.props.fileTransferService.fileAsFileTransfer(
                this.state.selectedProject?.key as string,
                fileTransferParams,
                messageNrn,
                attachments,
                this.fileUploadCallback.bind(this),
                this.handleProgressEvent.bind(this),
                this.props.isFileTransferAndEditorSupported
            );
            Log.info("fileAsFileTransfer finished");
            this.sendingTextToAppHeader(
                this.props.translate("APP.FILE_TRANSFER.SUCCESS_MESSAGE") as string,
                MessageBarType.success
            );
            return this.onShowToast(
                this.props.translate("APP.FILE_TRANSFER.SUCCESS_MESSAGE") as string,
                MessageBarType.success
            );
        } catch (error) {
            const correctErrorMessage = this.state.isCancelCorrect
                ? (this.props.translate("APP.FILE_TRANSFER.CANCEL_ERROR") as string)
                : (this.props.translate("APP.FILE_TRANSFER.ERROR") as string);
            this.handleApiError(error, correctErrorMessage);
            this.sendingTextToAppHeader(correctErrorMessage, MessageBarType.severeWarning);
        } finally {
            this.setState({
                isFileTransferInProgress: false,
                isCancelInProgress: false,
                isCancelCorrect: false,
            });
        }
    }

    private handleUserInteraction = (event: MouseEvent) => {
        this.onDismissToastMessage();
        if (this.container) {
            this.container.removeEventListener("click", this.handleUserInteraction);
        }
    };

    private async onShowToast(message: string | null, type: MessageBarType) {
        if (!message) {
            this.onDismissToastMessage();
            return;
        }
        this.setState({ toastMessage: message, toastType: type });
        if (type === MessageBarType.success && this.container) {
            this.container.addEventListener("click", this.handleUserInteraction);
        }
    }

    private onDismissPanel() {
        this.setState({
            attachments: [],
            subject: "",
            remarks: "",
            shouldClearEmails: false,
            shouldPersistSelectedValues: false,
        });
        this.props.onDismiss();
    }

    isReminderCheckBoxClicked() {
        this.setState({
            shouldSetReminder:
                this.state.shouldSetReminder && !this.state.projectSettings.hasDefaultZeroValue ? false : true,
        });
    }

    sendingTextToAppHeader(toastText: string, toastType: MessageBarType) {
        this.props.changeAppHeaderText(toastText, toastType);
    }
    isMoreOptionsDisabled(): boolean {
        const isDisabled =
            this.state.isFileTransferInProgress ||
            this.state.isSettingsLoading ||
            !this.state.selectedProject ||
            this.state.isClearForm;

        return isDisabled;
    }

    private onDismissToastMessage() {
        this.setState({ toastMessage: undefined, toastType: undefined });
        this.props.onDismissToastMessage();
    }

    shouldCollapseMoreOptions() {
        this.setState({ shouldCollapseMoreOptions: true });
    }

    shouldExpandMoreOptions() {
        this.setState({ shouldCollapseMoreOptions: false });
    }

    private onCCAndToComparison(areToAndCCSame: boolean): void {
        this.setState({ areToAndCCSame });
    }

    render(): JSX.Element {
        return (
            <div className="newforma-fileTransferPage">
                <Panel
                    className="newforma-fileTransferPanel"
                    data-theme={this.props.theme}
                    isOpen={this.props.showPanel}
                    closeButtonAriaLabel={this.props.translate("APP.MESSAGEBARS.CLOSE") as string}
                    onOuterClick={() => {}}
                    onDismiss={() => this.onDismissPanel()}
                    onOpened={this.onPanelOpen.bind(this)}
                    headerText={this.props.translate("APP.COMMANDBAR.FILE_TRANSFER") as string}
                    layerProps={{ eventBubblingEnabled: true }} // this is necessary for panels to keep existing functionality: https://github.com/microsoft/fluentui/issues/12059
                    allowTouchBodyScroll={true}
                >
                    <div
                        className="newforma-messageBarContainer newforma-messageBar-fileTransfer"
                        hidden={!this.state.toastMessage || !this.props.appToastMessage}
                    >
                        <MessageBar
                            className={
                                this.state.toastType === MessageBarType.success ||
                                this.state.toastType === MessageBarType.severeWarning
                                    ? this.state.toastType === MessageBarType.severeWarning
                                        ? "newforma-messageBar-oneLine newforma-messageBar"
                                        : "newforma-messageBar"
                                    : "newforma-messageBarInfo"
                            }
                            messageBarType={this.state.toastType}
                            isMultiline={false}
                            onDismiss={() => this.onDismissToastMessage()}
                        >
                            {this.state.toastMessage}
                        </MessageBar>
                    </div>
                    <div className="newforma-fileTransferComponent">
                        {this.state.isFileTransferInProgress || this.state.isCancelInProgress ? (
                            <>
                                <div className="progressBarContainer">
                                    <p>{this.props.translate("APP.FILE_TRANSFER.PROGRESS_HEADER")}</p>
                                    <ProgressIndicator
                                        label={this.props.translate("APP.FILE_TRANSFER.PROGRESS_LABEL")}
                                    />
                                    <p className="progressStatus">
                                        {this.props.translate("APP.FILE_TRANSFER.PROGRESS_STATUS")}
                                        {this.state.fileUploadCompletedCount}
                                        {this.props.translate("APP.FILE_TRANSFER.PROGRESS_STATUS_DELIMITER")}
                                        {this.state.fileUploadCount}
                                    </p>
                                    <div className="newforma-inputLinkContainer">
                                        <div
                                            onClick={() =>
                                                this.setState({
                                                    isFileTransferInProgress: this.state.isFileTransferInProgress
                                                        ? false
                                                        : true,
                                                    isCancelInProgress: true,
                                                })
                                            }
                                            className="newforma-inputLinkButton"
                                        >
                                            <p>
                                                <br />
                                                {this.props.translate("APP.FILE_TRANSFER.CANCEL_HEADER") as string}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <MessageDialogComponent
                                    title={this.props.translate("APP.FILE_TRANSFER.CANCEL_HEADER") as string}
                                    subText={this.props.translate("APP.FILE_TRANSFER.CANCEL_TEXT") as string}
                                    hidden={this.state.isCancelInProgress === false}
                                    theme={this.props.theme}
                                    onDismiss={() => {
                                        this.setState({ isCancelInProgress: false, isFileTransferInProgress: true });
                                    }}
                                    onConfirmButtonClicked={async () => {
                                        this.setState({
                                            isCancelInProgress: false,
                                            isFileTransferInProgress: false,
                                            isCancelCorrect: true,
                                        });
                                        await this.props.fileTransferService.abortFileTransfer();
                                    }}
                                />
                            </>
                        ) : (
                            <div className="newforma-fileTransfer newforma-form">
                                <>
                                    <Stack className="fileTransferStack">
                                        <Separator alignContent="end" className="separator">
                                            <div className="newforma-inputLinkContainer clearForm">
                                                <Icon
                                                    iconName="EraseTool"
                                                    className="newforma-attachmentsErase newforma-inputLinkIcon"
                                                    onClick={this.onClearForm.bind(this)}
                                                />
                                                <div
                                                    className="newforma-inputLinkButton"
                                                    onClick={this.onClearForm.bind(this)}
                                                >
                                                    {this.props.translate("APP.FILE_TRANSFER.CLEAR") as string}
                                                </div>
                                            </div>
                                        </Separator>
                                    </Stack>

                                    <SuggestedProjectPickerComponent
                                        logger={this.props.logger}
                                        className="newforma-formSpacing"
                                        projects={this.state.projects}
                                        onProjectSelected={this.onProjectSelected.bind(this)}
                                        disabled={this.state.isLoadingProjects || this.state.isFileTransferInProgress}
                                        smartFilingManager={this.props.smartFilingManager}
                                        isLoadingProjects={this.state.isLoadingProjects}
                                        mailboxItem={this.props.mailboxItem}
                                        onRefresh={this.onRefresh.bind(this)}
                                        theme={this.props.theme}
                                        myProjects={this.state.projects}
                                        isClearForm={this.state.isClearForm}
                                        selectedProject={this.state.selectedProject}
                                    />
                                    <Label required={true}>
                                        {this.props.translate("APP.FILE_TRANSFER.SUBJECT_LABEL") as string}
                                    </Label>
                                    <TextField
                                        className="newforma-formSpacing"
                                        id="fileTransfer-subject"
                                        value={this.state.subject}
                                        onChange={this.onSubjectChange.bind(this)}
                                        disabled={this.state.isFileTransferInProgress}
                                        maxLength={255}
                                    />
                                    {this.state.showPurposeField && this.state.purposes.length > 0 ? (
                                        <KeywordsDropdown
                                            id="filetransfer-purposePicker"
                                            className="newforma-fileTransferPurpose newforma-formSpacing"
                                            options={this.state.purposes} // hard coded right now
                                            label={this.props.translate("APP.FILE_TRANSFER.PURPOSE.LABEL") as string}
                                            placeholder={
                                                this.props.translate("APP.FILE_TRANSFER.PURPOSE.PLACEHOLDER") as string
                                            }
                                            disabled={
                                                !this.state.selectedProject ||
                                                this.state.isLoadingKeywords ||
                                                this.state.isFileTransferInProgress ||
                                                !this.state.purposes.length
                                            }
                                            isLoading={this.state.isLoadingKeywords}
                                            isProjectSelected={!!this.state.selectedProject}
                                            required={true}
                                            onSelectionChange={this.onPurposeSelectionChange.bind(this)}
                                            selectFirstOption={false}
                                            theme={this.props.theme}
                                            selectedPurposeKey={
                                                this.state.selectedPurpose
                                                    ? this.state.selectedPurpose.displayOrder
                                                    : null
                                            }
                                        />
                                    ) : null}
                                    <FromAndToComponent
                                        className={"newforma-formSpacing"}
                                        isFromRequired={false}
                                        isToRequired={true}
                                        isCcRequired={false}
                                        includeCc={true}
                                        officeWrapper={this.props.officeWrapper}
                                        disabled={this.state.isFileTransferInProgress}
                                        formValidationHelpers={this.props.formValidationHelpers}
                                        mailboxItem={this.props.mailboxItem}
                                        onFromChange={this.onFromChange.bind(this)}
                                        onToChange={this.onToChange.bind(this)}
                                        onCcChange={this.onCcChange.bind(this)}
                                        logger={this.props.logger}
                                        projectsService={this.props.projectsService}
                                        onError={this.onShowToast.bind(this)}
                                        project={this.state.selectedProject}
                                        shouldHideFrom={true}
                                        shouldClearEmails={this.state.shouldClearEmails || this.state.isClearForm}
                                        isTransferFailed={this.state.shouldPersistSelectedValues}
                                        EmailRecipients={this.state.emailRecipients}
                                        isFileTransferComponent={true}
                                        onEmailCheck={this.onCCAndToComparison.bind(this)}
                                        type="fileTransfer"
                                    />
                                    <Label required={false}>
                                        {this.props.translate("APP.FILE_TRANSFER.REMARKS_LABEL") as string}
                                    </Label>
                                    {this.props.isFileTransferAndEditorSupported ? (
                                        <HTMLEditor
                                            value={this.state.remarks}
                                            onRemarksUpdate={this.onRemarksChange.bind(this)}
                                            configService={this.props.configService}
                                            isFiling={this.state.isFileTransferInProgress}
                                        />
                                    ) : (
                                        <TextField
                                            className="newforma-formSpacing"
                                            id="fileTransfer-remarks"
                                            multiline
                                            resizable={true}
                                            value={this.state.remarks}
                                            onChange={this.onOldEditorRemarksChange.bind(this)}
                                            disabled={this.state.isFileTransferInProgress}
                                            rows={5}
                                            maxLength={65000}
                                        />
                                    )}
                                    <ChipPickerComponent
                                        className="newforma-formSpacing newforma-fileTransferKeywords"
                                        required={false}
                                        disabled={
                                            this.state.isFileTransferInProgress ||
                                            this.state.isLoadingProjects ||
                                            !this.state.selectedProject
                                        }
                                        items={[]}
                                        selectedItems={this.state.selectedKeywords}
                                        onSelectedItemsChanged={this.onKeywordsChange.bind(this)}
                                        label={this.props.translate("APP.FILE_TRANSFER.KEYWORDS_LABEL") as string}
                                        allowCustomInput={true}
                                        keywordFilterProps={{
                                            filter: this.props.projectsService.getProjectKeywords.bind(
                                                this.props.projectsService
                                            ),
                                            projectNrn: this.state.selectedProject?.key,
                                            keywordListType: KeywordListType.TransmittalKeywords,
                                            onError: this.handleKeywordError.bind(this),
                                        }}
                                    />
                                    <AttachmentsComponent
                                        attachments={this.state.attachments}
                                        disabled={this.state.isFileTransferInProgress}
                                        onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                                        allowFileUploads={true}
                                        required={true}
                                        logger={this.props.logger}
                                        isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                                        isFileTransferAndEditorSupported={this.props.isFileTransferAndEditorSupported}
                                        classNameForScroll=".ms-Panel-content"
                                        updateMoreOptions={this.shouldCollapseMoreOptions.bind(this)}
                                        expandMoreOptions={this.shouldExpandMoreOptions.bind(this)}
                                    />
                                    <MoreOptionsComponent
                                        key={this.state.selectedProject ? this.state.selectedProject.name : undefined}
                                        disabled={this.isMoreOptionsDisabled()}
                                        onExpand={this.onExpandMoreOptions.bind(this)}
                                        projectSettings={this.state.projectSettings}
                                        isProjectChanged={this.state.isSettingsLoading}
                                        onReminderClicked={this.isReminderCheckBoxClicked.bind(this)}
                                        storageWrapper={this.props.storageWrapper}
                                        dateHelpers={this.props.dateHelpers}
                                        selectedProject={this.state.selectedProject}
                                        isTransferFailed={this.state.shouldPersistSelectedValues}
                                        moreOptionsDetails={this.state.moreOptionsDetails}
                                        mailboxItem={this.props.officeWrapper.currentContextItem}
                                        collapseMoreOptions={this.state.shouldCollapseMoreOptions}
                                    />

                                    <div id="footer" key="footer" className="newforma-footer">
                                        <DefaultButton
                                            key="fileAsFileTransferSubmitButton"
                                            className="newforma-footerButton"
                                            id="fileFileTransferButton"
                                            primary={true}
                                            onClick={this.onFormSubmit.bind(this)}
                                            text={(this.props.translate(
                                                "APP.FILE_TRANSFER.SUBMIT_BUTTON"
                                            ) as string).toLocaleUpperCase()}
                                            disabled={
                                                !this.isFormValid() ||
                                                this.state.isFileTransferInProgress ||
                                                this.state.isSettingsLoading
                                            }
                                        />
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                </Panel>
            </div>
        );
    }
}
export default withLocalize(FileTransferComponent);
