import * as React from "react";
import "./SuggestedProjectPickerComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import {
    DirectionalHint,
    ICalloutProps,
    ISuggestionItemProps,
    ITooltipHostStyles,
    TagItemSuggestion,
    TagPicker,
    TooltipHost,
} from "office-ui-fabric-react";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { Logger } from "../../../services/Logger";
import { MailboxItem } from "../../../services/OfficeWrapper";
import RefreshProjectComponent from "../refreshProject/RefreshProjectComponent";
import { SuggestedProject } from "../../../services/SmartFiling/SuggestedProject";
import { ITagWithNixEnabled } from "../../../models/shared/ITagWithNixEnabed";

export interface SuggestedProjectPickerComponentProps extends LocalizeContextProps {
    logger: Logger;
    className?: string;
    onProjectSelected: (project: ITagWithNixEnabled | null) => void;
    projects: ITagWithNixEnabled[];
    myProjects?: ITagWithNixEnabled[];
    smartFilingManager: SmartFilingManager;
    disabled: boolean;
    isLoadingProjects: boolean;
    mailboxItem: MailboxItem | null;
    onRefresh: () => void;
    theme: string;
    isLoadingRemainingProjects?: boolean;
    isClearForm?: boolean;
    selectedProject?: ITagWithNixEnabled | null;
    updateProjectsToBeRendered?: (projects: ProjectITag[]) => void;
    projectsToBeRendered?: ProjectITag[];
}

export interface SuggestedProjectPickerComponentState {
    selectedProject: ITagWithNixEnabled | null;
    projectsWithSuggestedToBeRendered: ProjectITag[];
    pickerKey: number;
    isLoadingSuggestedProject: boolean;
    suggestedProjectsPickerWidth: number;
    suggestedProjectsPickerHeight: number;
}

class SuggestedProjectPickerComponent extends React.Component<
    SuggestedProjectPickerComponentProps,
    SuggestedProjectPickerComponentState
> {
    private suggestedKeyword: string = "";
    private readonly suggestedTitleKey: string = "suggested-items-title";
    private readonly myProjectsTitleKey: string = "myProjects-items-title";
    private readonly moreProjectsTitleKey: string = "moreProjects-items-title";
    private readonly suggestedProjectCount: number = 3;
    private hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block", width: `100%` } };
    private calloutProps: ICalloutProps = { gapSpace: 0, directionalHint: DirectionalHint.topCenter };
    private readonly maxProjects: number = 25;

    constructor(props: SuggestedProjectPickerComponentProps, context: SuggestedProjectPickerComponentState) {
        super(props, context);

        this.state = {
            selectedProject: null,
            projectsWithSuggestedToBeRendered: [],
            pickerKey: 0,
            isLoadingSuggestedProject: false,
            suggestedProjectsPickerWidth: 255,
            suggestedProjectsPickerHeight: 298,
        };
    }

    async componentDidMount() {
        this.suggestedKeyword = this.props.translate("SHARED.SUGGESTED_PROJECTS.SUGGESTED") as string;
        if (this.props.selectedProject) {
            this.setState({
                selectedProject: this.props.selectedProject,
                projectsWithSuggestedToBeRendered: this.props.projectsToBeRendered
                    ? this.props.projectsToBeRendered
                    : [],
            });
        }
    }

    async componentDidUpdate(
        prevProps: SuggestedProjectPickerComponentProps,
        prevState: SuggestedProjectPickerComponentState
    ): Promise<void> {
        if (
            prevProps.myProjects !== this.props.myProjects ||
            prevProps.mailboxItem !== this.props.mailboxItem ||
            (this.props.selectedProject && this.state.selectedProject === null)
        ) {
            if (this.props.selectedProject) {
                this.setState({ selectedProject: this.props.selectedProject });
                this.props.onProjectSelected(this.props.selectedProject);
            }
            await this.determineSuggestedProjectsAndProjectsToBeRendered(this.isMoreProjectHeaderAdded());
        }
        if (
            prevProps.projects !== this.props.projects &&
            this.props.projects !== this.props.myProjects &&
            !this.isMoreProjectHeaderAdded()
        ) {
            const uniqueProjectsToBeRendered = this.state.projectsWithSuggestedToBeRendered;
            if (uniqueProjectsToBeRendered[0]?.key !== "moreProjects-items-title") {
                uniqueProjectsToBeRendered.unshift(this.getMoreProjectsHeader());
            }
            this.setState({
                projectsWithSuggestedToBeRendered: uniqueProjectsToBeRendered,
            });
            if (this.props.updateProjectsToBeRendered) {
                this.props.updateProjectsToBeRendered(uniqueProjectsToBeRendered);
            }
        }
    }

    private isMoreProjectHeaderAdded() {
        const lastProjectKey = this.state.projectsWithSuggestedToBeRendered.slice(-1)[0];
        return lastProjectKey !== undefined && lastProjectKey.key === this.moreProjectsTitleKey;
    }

    private onProjectsFiltered(filter: string, selectedItems?: ITagWithNixEnabled[]): ProjectITag[] {
        if (!filter) {
            return this.state.projectsWithSuggestedToBeRendered;
        }
        const uniqueProjects = this.props.projects;
        const filteredProjects = uniqueProjects.filter((project) =>
            project.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        );
        // update the width of the callout when projects shown, if width of add-in is changed in desktop outlook add-in
        this.updateProjectsCalloutWidth();

        const filteredProjectArrayToBeRendered = filteredProjects.slice(0, this.maxProjects);
        return filteredProjectArrayToBeRendered.map((p) => ({
            ...p,
            className: "newforma-projectNameItem",
        }));
    }

    private updateProjectsCalloutWidth(): void {
        const projectComponentWidth = document.querySelector(".newforma-suggestedProjectPicker")?.clientWidth || 255;
        const searchSuggestedProjectPicker = document.querySelector(".search-suggestedProjectPickerComponent");
        const teamSuggestedProjectPicker = document.querySelector(".team-suggestedProjectPickerComponent");
        this.setState({
            suggestedProjectsPickerWidth:
                searchSuggestedProjectPicker || teamSuggestedProjectPicker
                    ? projectComponentWidth + 18
                    : projectComponentWidth + 4,
        });
    }

    private onEmptyInputFocus(selectedItems?: ITagWithNixEnabled[]): ITagWithNixEnabled[] {
        // update the width of the callout when projects shown, if width of add-in is changed in desktop outlook add-in
        this.updateProjectsCalloutWidth();
        return this.state.projectsWithSuggestedToBeRendered;
    }

    private onProjectSelected(selectedItem?: ITagWithNixEnabled): ITagWithNixEnabled | null {
        if (
            !selectedItem ||
            selectedItem.key === this.suggestedTitleKey ||
            selectedItem.key === this.myProjectsTitleKey ||
            selectedItem.key === this.moreProjectsTitleKey
        ) {
            this.setState({ selectedProject: null });
            this.props.onProjectSelected(null);
            return null;
        }
        this.setState({ selectedProject: selectedItem });
        this.props.onProjectSelected(this.getProjectWithoutSuggestedSuffix(selectedItem));
        return selectedItem;
    }

    private onProjectPickerChange(items?: ITagWithNixEnabled[]): void {
        if (!items?.length) {
            this.props.onProjectSelected(null);
            this.setState({ selectedProject: null });
        } else {
            this.props.onProjectSelected(items[0]);
            this.setState({ selectedProject: items[0] });
        }
    }

    private onRefresh(): void {
        this.setState({ selectedProject: null });
        this.props.onRefresh();
    }

    private async determineSuggestedProjectsAndProjectsToBeRendered(moreProjectHeaderEnabled: boolean) {
        this.setState((state) => ({
            pickerKey: state.pickerKey + 1,
            isLoadingSuggestedProject: true,
        }));
        const suggestedProjects = await this.getSuggestedProjects();
        let uniqueProjectsToBeRendered: ProjectITag[] = suggestedProjects;
        let isAddMoreProjectHeader = moreProjectHeaderEnabled;
        if (this.props.myProjects) {
            const uniqueMyProjects = this.excludeProjects(this.props.myProjects, suggestedProjects);
            if (uniqueMyProjects?.length) {
                uniqueProjectsToBeRendered = uniqueProjectsToBeRendered.concat(this.getMyProjectsHeader()).concat(
                    uniqueMyProjects.slice(0, this.maxProjects).map((p) => ({
                        ...p,
                        className: "newforma-projectNameItem",
                    }))
                );
                isAddMoreProjectHeader = moreProjectHeaderEnabled || uniqueMyProjects.length > this.maxProjects;
            }
        }
        if (isAddMoreProjectHeader || this.props.isLoadingRemainingProjects) {
            if (uniqueProjectsToBeRendered[0]?.key !== "moreProjects-items-title") {
                uniqueProjectsToBeRendered.unshift(this.getMoreProjectsHeader());
            }
        }

        // this allows prefilling of selectedProject while global project list is loading in background
        if (!this.state.selectedProject && suggestedProjects[1]) {
            if (suggestedProjects[1].key === "suggested-items-title" && suggestedProjects[2]) {
                this.setState({ selectedProject: suggestedProjects[2] });
            } else if (suggestedProjects[1].key === "suggested-items-title" && !suggestedProjects[2]) {
                this.setState({ selectedProject: null });
            } else {
                this.setState({ selectedProject: suggestedProjects[1] });
            }
            this.props.onProjectSelected(this.getProjectWithoutSuggestedSuffix(suggestedProjects[1]));
        } else if (this.state.selectedProject) {
            this.setState({ selectedProject: this.state.selectedProject });
            this.props.onProjectSelected(this.getProjectWithoutSuggestedSuffix(this.state.selectedProject));
        } else {
            this.props.onProjectSelected(null);
        }
        this.props.logger.info(
            "SuggestedProjectPickerComponent. Determine suggested projects and projects to be rendered",
            uniqueProjectsToBeRendered
        );
        this.setState({
            projectsWithSuggestedToBeRendered: uniqueProjectsToBeRendered,
            isLoadingSuggestedProject: false,
        });
        if (this.props.updateProjectsToBeRendered) {
            this.props.updateProjectsToBeRendered(uniqueProjectsToBeRendered);
        }
    }

    private async getSuggestedProjects(): Promise<(SuggestedProjectITag | ProjectITag)[]> {
        try {
            const suggestionResults = await this.props.smartFilingManager.getSuggestedProjects(this.props.projects);
            if (suggestionResults.length) {
                return [this.getSuggestedProjectsHeader()].concat(
                    suggestionResults.slice(0, this.suggestedProjectCount).map((project, index) => ({
                        name: `${project.name}`,
                        key: project.nrn,
                        suggestedProject: project,
                        suggestionIndex: index,
                        className: "newforma-suggestedItem",
                        tagItemSuggestionClassName: "tagItemSuggestionClassName",
                        nixEnabled: project.nixEnabled,
                    }))
                );
            }
        } catch (error) {
            this.props.logger.error(
                "SuggestedProjectPickerComponent. There was an error retrieving suggested projects",
                error
            );
        }
        return [];
    }

    private excludeProjects<T extends ITagWithNixEnabled>(projects: T[], suggestedProjects: ITagWithNixEnabled[]): T[] {
        return projects?.filter((project) => !suggestedProjects.some((suggestion) => suggestion.key === project.key));
    }

    private getProjectWithoutSuggestedSuffix(project: ITagWithNixEnabled): ITagWithNixEnabled {
        if (project.name.endsWith(this.suggestedKeyword)) {
            return { ...project, name: project.name.slice(0, project.name.lastIndexOf(this.suggestedKeyword)).trim() };
        }

        return project;
    }

    private getPlaceholder(): string {
        const value =
            this.props.isLoadingProjects || this.state.isLoadingSuggestedProject
                ? this.props.translate("SHARED.SUGGESTED_PROJECTS.LOADING_PROJECTS")
                : this.props.translate("SHARED.SUGGESTED_PROJECTS.PLACEHOLDER");
        return value as string;
    }

    private getSuggestedProjectsHeader(): ProjectITag {
        return {
            name: this.props.translate("SHARED.SUGGESTED_PROJECTS.SUGGESTED_PROJECTS_TITLE") as string,
            key: this.suggestedTitleKey,
            className: "ms-Suggestions-title",
        };
    }
    private getMyProjectsHeader(): ProjectITag {
        return {
            name: this.props.translate("SHARED.MY_PROJECTS") as string,
            key: this.myProjectsTitleKey,
            className: "newforma-projectNameItem",
        };
    }
    private getMoreProjectsHeader(): ProjectITag {
        return {
            name: this.props.translate("FILE_MULTIPLE_EMAIL.USE_SEARCH") as string,
            key: this.moreProjectsTitleKey,
            className: "newforma-projectNameItem",
        };
    }

    onRenderSuggestionsItem(
        item: ITagWithNixEnabled,
        itemProps: ISuggestionItemProps<ITagWithNixEnabled>
    ): JSX.Element {
        const project = item as ProjectITag;
        return (
            <div key="projectItemBlock" className={`${project.className}`} data-theme={this.props.theme}>
                {item.key === this.moreProjectsTitleKey ? (
                    <div key="moreProjectItemBlock">
                        <div key="moreProjectItem" className="newforma-projectText">
                            <span key="projectPickerText" className="projectPickerText">
                                {this.props.translate("PROJECT_PICKER.PART_1")}
                            </span>
                            <span key="projectPickerBoldText" className="projectPickerText projectPickerBoldText">
                                {this.props.translate("PROJECT_PICKER.PART_2")}
                            </span>
                            <span key="projectPickerNextPartText" className="projectPickerText">
                                {this.props.translate("PROJECT_PICKER.PART_3")}
                            </span>
                        </div>
                        {this.props.isLoadingRemainingProjects ? (
                            <div key="remainingProjectsItemBlock" className="newforma-projectText">
                                <span key="remainingProjectsItem" className="projectPickerText">
                                    {this.props.translate("PROJECT_PICKER.PART_4")}
                                </span>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <TooltipHost
                        key="projectTooltip"
                        content={item.name}
                        calloutProps={this.calloutProps}
                        styles={this.hostStyles}
                    >
                        <TagItemSuggestion key="projectTagItem">
                            <span key="projectItem" className={`${project.tagItemSuggestionClassName ?? ""}`}>
                                {item.name}
                            </span>
                        </TagItemSuggestion>
                    </TooltipHost>
                )}
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div className="newforma-suggestedProjectPickerComponent" key="suggestedProjectPicker">
                <RefreshProjectComponent
                    key="refreshProject"
                    labelText={this.props.translate("SHARED.SUGGESTED_PROJECTS.LABEL") as string}
                    required={true}
                    onRefresh={this.onRefresh.bind(this)}
                ></RefreshProjectComponent>
                <TagPicker
                    key={this.state.pickerKey}
                    className={`newforma-suggestedProjectPicker ${this.props.className} ${
                        this.props.disabled || this.state.isLoadingSuggestedProject
                            ? "newforma-disabledProjectPicker"
                            : ""
                    }`}
                    selectedItems={
                        this.state.selectedProject && !this.props.isClearForm ? [this.state.selectedProject] : []
                    }
                    pickerSuggestionsProps={{
                        noResultsFoundText: this.props.translate("SHARED.SUGGESTED_PROJECTS.NO_PROJECTS") as string,
                        suggestionsItemClassName: "newforma-projectCallout",
                    }}
                    disabled={this.props.disabled || this.state.isLoadingSuggestedProject}
                    itemLimit={1}
                    onResolveSuggestions={this.onProjectsFiltered.bind(this)}
                    onRenderSuggestionsItem={this.onRenderSuggestionsItem.bind(this)}
                    onItemSelected={this.onProjectSelected.bind(this)}
                    onChange={this.onProjectPickerChange.bind(this)}
                    onEmptyResolveSuggestions={this.onEmptyInputFocus.bind(this)}
                    inputProps={{
                        placeholder: this.getPlaceholder(),
                        style: {
                            maxWidth: this.state.suggestedProjectsPickerWidth,
                        },
                    }}
                    pickerCalloutProps={{
                        className: "newforma-suggestedProjectCallout",
                        calloutWidth: this.state.suggestedProjectsPickerWidth,
                        calloutMaxWidth: this.state.suggestedProjectsPickerWidth,
                        directionalHint: DirectionalHint.bottomLeftEdge,
                        style: {
                            maxHeight: this.state.suggestedProjectsPickerHeight,
                        },
                    }}
                />
            </div>
        );
    }
}

export default withLocalize(SuggestedProjectPickerComponent);

export interface ProjectITag extends ITagWithNixEnabled {
    className: string;
    tagItemSuggestionClassName?: string;
}

export interface SuggestedProjectITag extends ProjectITag {
    suggestedProject: SuggestedProject;
    suggestionIndex: number;
}
